import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { API } from '../services/api.service';
import { Config } from '../models/env-vars.model';
import { Projects } from './projects.service';
import { ArcAuthService } from './auth.service';
import { AppData } from './appdata.service';
import { alertService } from './alert-service.service';
import { ScoreCodeVersionService } from './score-code-version.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as SideBar from '../components/modal/template-wrapper';

import * as bootstrap from 'bootstrap';
// import * as $ from 'jquery';
import 'moment-timezone';
import * as moment from 'moment';
import { LanguageService } from './language.service';
import { AuthService } from '@auth0/auth0-angular';
import * as FS from '@fullstory/browser';
import * as stream from 'getstream';

declare var tippy: any;
declare var Dropbox: any;
declare var OneDrive: any;
declare var platformApi: any;

@Injectable({providedIn: 'root'})
export class Global
{
	project_type: string = 'all';
	interval: any;

	constructor(
		private appData: AppData,
		private router: Router,
		private api: API,
		private projects: Projects,
		private auth: ArcAuthService,
		private alertService: alertService,
		private ScoreCodeVersion: ScoreCodeVersionService,
		private modalService: NgbModal,
		private language: LanguageService,
		private _ngZone: NgZone,
		private auth0: AuthService
	)
	{}

	initApp()
	{
		try
		{
			platformApi.initialize();
		}
		catch(e)
		{
			console.log('platformApi init error')
		}
		this.appData.set('space_type_gsa', Config.config.settings.space_type_gsa);
		this.appData.set('change_payment_method', false);
		this.appData.set('landing_page_url', Config.config.settings.logout_url.split('/arc-user-logout')[0]);

		this.fetchPrice();
		this.language.initLanguage();
		
		if(this.appData.get('current_app') == 'selected_project')
		{
			this.userAccessLevel(this.appData.get('leed_id'));
		}
		else if(this.appData.get('current_app') == 'selected_portfolio')
		{
			this.getPortfolioTeam();
		}

		$('body').click(e =>
		{
			if($(e.target).attr('(click)') == 'acceptAgreement()')
			{
				this.acceptAgreement(false);
			}
		});
	}

	getUrlVars()
	{
		var vars: any = [], hash;
		var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
		for(var i = 0; i < hashes.length; i++)
		{
			hash = hashes[i].split('=');
			vars.push(hash[0]);
			vars[hash[0]] = hash[1];
		}
		return vars;
	}

	toggleMenu()
	{
		let elem: any = document.querySelector('.sidebar_nav');
		let side: any = document.querySelector('#sidebar');
		if(!elem.classList.contains('open'))
		{
			elem.classList.add('open');
			if(side != null)
			{
				side.classList.add('open');
			}
		}
		else
		{
			elem.classList.remove('open');
			if(side != null)
			{
				side.classList.remove('open');
			}
		}
	}

	postLoginAppInit()
	{
		let sub_domains = window.location.pathname.replace('/', '').split("/");

		if(this.appData.get('post_sso_redirect') != undefined)
		{
			sub_domains = this.appData.get('post_sso_redirect').split("/");
			this.appData.set('post_sso_redirect', undefined);
		}

		if(sub_domains[0] == 'login_middleware')
		{
			this.goToApp('home');
		}
		else if(sub_domains[0] == 'cagbc-admin')
		{
			this.goToApp('cagbc_review_admin');
		}
		else if(sub_domains[2] == 'my-projects')
		{
			this.goToApp('projects');
		}
		else if(sub_domains[2] == 'my-portfolios')
		{
			this.goToApp('portfolios');
		}
		else if(sub_domains[1] == 'project' && !isNaN(parseInt(sub_domains[2])))
		{
			let leed_id = sub_domains[2];
			let app_name = sub_domains[3];

			if(app_name == undefined || Config.project_apps.indexOf(app_name) == -1)
			{
				app_name = 'project-overview';
			}

			this.searchMoveToProject({
				"leed_id": leed_id
			}, null, app_name);
		}
		else if(sub_domains[1] == 'portfolio' && !isNaN(parseInt(sub_domains[2])))
		{
			let pf_id = sub_domains[2];
			let app_name = sub_domains[3];

			if(app_name == undefined || Config.portfolio_apps.indexOf(app_name) == -1)
			{
				app_name = 'portfolio-overview';
			}

			this.searchMoveToPortfolio(pf_id, app_name);
		}
		else
		{
			this.goToApp('home');
		}
	}

	initSession(initlogin?: string)
	{
		// Moved to AppBootstrapService
	}

	initBanners()
	{
		this.api.getAsset(
			'../../assets/json/banner.json'
		).subscribe(
			data =>
			{
				Config.banner_message = data.banner_message;
				Config.need_banner = data.need_banner;

				if (Config.need_banner && !this.appData.get('closed_message_banner'))
				{
					$( ".message-banner" ).slideDown( "slow");
				}
			},
			error =>
			{

			}
		);

		this.dataPolling();
		this.interval = setInterval(()=>
		{
			this.dataPolling();
		}, 10000);
	}

	remove_arc_banner()
	{
		$( ".message-banner" ).slideUp( "slow");
		this.appData.set('closed_message_banner', true);
	}

	openLink(url: string, new_tab?: any)
	{
		window.open(url, new_tab);
	}

	openMail(email: string)
	{
		window.open('mailto:'+ email, '_self');
	}

	getUsername()
	{
		return this.appData.get('username');
	}

	dateFormat(dateTime?: string, none?: boolean, format?: string)
	{
		if(dateTime == undefined)
		{
			if(none)
			{
				return "-"
			}
			return moment(new Date()).format("MMM DD, YYYY");  
		}
		else
		{
			if(format == undefined)
			{
				format = "YYYY-MM-DD";
			}
			return moment.tz(dateTime, moment.tz.guess()).format("MMM DD, YYYY"); 
		}
	}

	dateTimeFormat(dateTime?: string)
	{
		if(dateTime == undefined)
		{
			return moment(new Date()).format("MMM DD, YYYY [at] hh:mm A");  
		}
		else
		{
			return moment.tz(dateTime, moment.tz.guess()).format("MMM DD, YYYY [at] hh:mm A"); 
		}
	};
	

	underscoreless(project: any)
	{
		var status = project.building_status;
            
		if(project.is_trial_selected && project.trial_expire_date)
		{
			if((project.project_type == 'building' || project.project_type == 'school') && String(project.leed_id)[0] == "9")
			{
				status = 'setup_incomplete';
			}
			else if(moment(new Date()).isBefore(project.trial_expire_date))
			{
				return "<span class='fw-600 fontSize13'>Trial</span> - Access Expires <br>" + this.dateFormat(project.trial_expire_date);
			}
			else
			{
				return '<div class="trial-expired"><div class="error_symbol"></div><div><p class="error">Access Expired</p><p>'+ this.dateFormat(project.trial_expire_date) +'</p></div></div>';
			}
		}

		if(status == undefined)
			return ''
		if (status == "agreement_pending"){
			return "Sign agreement"
		}
		else if (status == "addendum_agreement_pending"){
			return "Sign agreement"
		}
		else if (status == "activated_agreement_pending"){//use
			return "Sign agreement"
		}
		else if (status == "activated_addendum_agreement_pending"){ 
			return "Sign agreement"
		}
		else if (status == "activated_payment_pending"){ //use, Activate now-->Reg form with popu;ate fields
			return "Make payment"
		}
		else if (status == "activated_payment_done"){ //use
			
			return "Registered"
		}
		else if (status == "activated_under_review"){
			
			return "Under Review"
		}
		else if (status == "activated_review_payment_pending"){
			
			return "Deactivated"
		}
		else{
			return status.replace(/\_/g, ' ');
		}
	}

	getCertLabel(project: any)
	{
		var cert = project.certification;
		if(cert == undefined)
			return "";
		var cert = cert.toLowerCase();
		if(project.project_type == 'parksmart')
		{
			return cert.replace("-", " ");
		}
		if(cert == "platinum")
			return "LEED Platinum";
		else if(cert.toLowerCase() == "certified")
			return "LEED Certified";
		else if(cert.toLowerCase() == "pre-certified")
			return "LEED Pre-Certified";
		else if(cert.toLowerCase() == "silver")
			return "LEED Silver";
		else if(cert.toLowerCase() == "gold")
			return "LEED Gold";
		else if(cert.toLowerCase() == "confidential")
			return "LEED Confidential";
		else
			return this.underscoreless(project);
	}

	addCommas(nStr: string | number)
	{
		nStr = String(nStr).replace(/,/g, "");
		if (nStr == null){
			return;
		}
		
		nStr += '';
		var x = nStr.split('.');
		var x1 = x[0];
		var x2 = x.length > 1 ? '.' + x[1] : '';
		var rgx = /(\d+)(\d{3})/;
		while (rgx.test(x1))
		{
			x1 = x1.replace(rgx, '$1' + ',' + '$2');
		}
		return x1 + x2;
	}

	goToApp(app: string, data?: any, queryParams?: any)
	{
		this.appData.set('insight_app', null);
		if(data == undefined && Config.project_apps.indexOf(app) != -1)
		{
			data = {
				"leed_id": this.appData.get('buildingData').leed_id,
				"app_name": app,
			};
			app = 'selected_project';
		}
		else if(data == undefined && Config.portfolio_apps.indexOf(app) != -1)
		{
			data = {
				"portfolio_id": this.appData.get('portfolio_id'),
				"app_name": app,
			};
			app = 'selected_portfolio';
		}
		if (data != undefined && data.app_name != 'review-selection' && data.app_name != 'review-payment' && data.app_name != 'review-success')
		{
			this.appData.set('reviewData',{});
		}
		this.appData.set('previous_current_nav', this.appData.get('current_nav'));
		this.appData.set('previous_current_app', this.appData.get('current_app'));
		this.appData.set('previous_app_name', this.appData.get('app_name'));
		this.appData.set('previous_app_data', this.appData.get('app_data'));
		if(app == 'projects' && this.appData.get('project_type') == null)
		{
			this.appData.set('project_type', 'all');
		}
		for(let idx in this.router.config)
		{
			let route: any = this.router.config[idx];
			if(route.data.app == app)
			{
				this.appData.set('current_nav', route.path);
				this.appData.set('current_app', route.data.app);
				this.appData.set('app_name', app);
				this.appData.set('app_data', data);
				let path = route.path;
				if(data != undefined)
				{
					for(let key in data)
					{
						if(key == 'app_name')
						{
							this.appData.set('app_name', data[key]);
						}
						path = path.replace(":" + key, data[key]);
					}
					Config.state_params = data;
				}
				if(queryParams != undefined)
				{
					this.router.navigate([path], {queryParams: queryParams});
				}
				else
				{
					this.router.navigate([path]);
				}
				break;
			}
		}
		window.scrollTo(
		{
			top: 0,
			behavior: 'smooth',
		});
	}

	redirect(url: string)
	{
		this.appData.set('current_nav', url);
		this.router.navigate([url]);
	};

	isCertDetailsReq = function(certificates: any)
	{
		var show = false;
		for(var i = 0; i < certificates.length; i++)
		{
			if(["Arc Energy", "Arc Water", "Arc Waste", "Arc Human Experience", "Arc Transportation"].indexOf(certificates[i].certification_level) == -1)
			{
				show = true;
				break;
			}
		}
		return show;
	};

	capitalFirstLetter = function(val: string)
	{
		return val[0].toUpperCase() +  val.slice(1); 
	};

	doSorting = function(obj: any)
	{
		var newList = [];
		
		for(var key in obj) {
			if(obj.hasOwnProperty(key)) {
				newList.push({code: key, name: obj[key]});
			}
		}
		
		newList = newList.sort(function(a, b) {
			return a.name.localeCompare(b.name);
		});
		
		return newList;
	};

	fetchCountryStates()
	{
		return this.api.get(
			'/country/states/'
		);
	};

	getState(state:string, country:string, NA?: boolean)
	{
		if(Config.countries == null || Config.countries.divisions[country] == undefined)
		{
			return NA ? 'Not Available' : state;
		}
		return Config.countries.divisions[country][state];
	};

	getCountry(country:string)
	{
		if(Config.countries == null)
		{
			return country;
		}
		return Config.countries.countries[country];
	};

	getCountryList()
	{
		return this.doSorting(Config.countries.countries);
	}

	getStateList(country: string)
	{
		return this.doSorting(Config.countries.divisions[country]);
	}

	slideDown(id: string, speed: number | undefined)
	{
		$("#" + id).slideDown(speed);
	}

	slideToggle(id: string, speed: number | undefined)
	{
		$("#" + id).slideToggle(speed);
	}

	isLEEDCertified(certification: any)
	{
		if (certification.toLowerCase() == "platinum" || certification.toLowerCase() == "gold" || certification.toLowerCase() == "silver" || certification.toLowerCase() == "certified"  || certification.toLowerCase().includes("-star") || certification.toLowerCase().includes("confidential"))
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	insertAction(project_type: string, action_list: any)
	{
		if(project_type == 'city' || project_type == 'community')
		{
			this.api.get(
				'/assets/LEED:'+ this.appData.get('leed_id') + '/measuresversion/base_score/'
			).subscribe(data =>
			{
				if(data.results.length == 0)
				{
					return data.results
				}
				let assigned_to = data.results[0]['assigned_to']
				let status = data.results[0]['status']
				for(let i = 0; i < this.appData.get('team_members').length; i++)
				{
					if(this.appData.get('team_members')[i].Useralias == assigned_to)
					{
						assigned_to = this.appData.get('team_members')[i].Firstname + " " + this.appData.get('team_members')[i].Lastname;
					}                    
				}
				action_list.push({"CreditId": "base_score", "CreditDescription": "Certification Plan", "CreditShortId": "base_score", "AssignedTo": assigned_to, 'CreditStatus': status});
				action_list.push({"CreditId": "base_score", "CreditDescription": "Benchmarking and evaluation plan", "CreditShortId": "base_score", "AssignedTo": assigned_to, 'CreditStatus': status});
				action_list.push({"CreditId": "base_score", "CreditDescription": "Carbon reduction plan", "CreditShortId": "base_score", "AssignedTo": assigned_to, 'CreditStatus': status});
				action_list.push({"CreditId": "base_score", "CreditDescription": "Resilience plan", "CreditShortId": "base_score", "AssignedTo": assigned_to, 'CreditStatus': status});
				action_list.push({"CreditId": "base_score", "CreditDescription": "Healthy community plan", "CreditShortId": "base_score", "AssignedTo": assigned_to, 'CreditStatus': status});
				action_list.push({"CreditId": "base_score", "CreditDescription": "Basic services plan", "CreditShortId": "base_score", "AssignedTo": assigned_to, 'CreditStatus': status});
				action_list.push({"CreditId": "base_score", "CreditDescription": "Biodiversity plan", "CreditShortId": "base_score", "AssignedTo": assigned_to, 'CreditStatus': status});
				action_list.push({"CreditId": "base_score", "CreditDescription": "Watershed, Coastal areas, or Ocean resources plan", "CreditShortId": "base_score", "AssignedTo": assigned_to, 'CreditStatus': status});
				action_list.push({"CreditId": "base_score", "CreditDescription": "Culture and heritage plan", "CreditShortId": "base_score", "AssignedTo": assigned_to, 'CreditStatus': status});
				action_list.push({"CreditId": "base_score", "CreditDescription": "Green infrastructure plan", "CreditShortId": "base_score", "AssignedTo": assigned_to, 'CreditStatus': status});

				this.appData.set('creditNavigator', action_list);
			});
		}
		return action_list
	}

	getActionList(forceCall: boolean)
	{
		if(this.appData.get('buildingData').is_trial_selected && this.appData.get('buildingData').activation_source != 'GRESB' && (this.appData.get('buildingData').project_type == 'city' || this.appData.get('buildingData').project_type == 'community'))
		{
			this.appData.set('all_actions', {
				"EtScorecardList": [
					{
						"CreditcategoryId": "CTP1PFL-",
						"CreditcategoryDescrption": "Performance Category",
						"CreditId": "CTP1PF901L-",
						"CreditDescription": "Energy",
						"CreditStatus": "Attempted",
						"CreditShortId": "PF901",
						"PointsAvailable": "0",
						"Mandatory": "",
						"PointsAttempted": "0.0",
						"PointsAwarded": "0.0",
						"PersonAssigned": "",
						"FormVersion": "",
						"AssignedTo": "",
						"IvAttchPolicy": "",
						"IvReqFileupload": ""
					},
					{
						"CreditcategoryId": "CTP1PFL-",
						"CreditcategoryDescrption": "Performance Category",
						"CreditId": "CTP1PF902L-",
						"CreditDescription": "Water",
						"CreditStatus": "Attempted",
						"CreditShortId": "PF902",
						"PointsAvailable": "0",
						"Mandatory": "",
						"PointsAttempted": "0.0",
						"PointsAwarded": "0.0",
						"PersonAssigned": "",
						"FormVersion": "V01",
						"AssignedTo": "",
						"IvAttchPolicy": "",
						"IvReqFileupload": ""
					},
					{
						"CreditcategoryId": "CTP1PFL-",
						"CreditcategoryDescrption": "Performance Category",
						"CreditId": "CTP1PF903L-",
						"CreditDescription": "Waste",
						"CreditStatus": "Attempted",
						"CreditShortId": "PF903",
						"PointsAvailable": "0",
						"Mandatory": "",
						"PointsAttempted": "0.0",
						"PointsAwarded": "0.0",
						"PersonAssigned": "",
						"FormVersion": "V01",
						"AssignedTo": "",
						"IvAttchPolicy": "",
						"IvReqFileupload": ""
					},
					{
						"CreditcategoryId": "CTP1PFL-",
						"CreditcategoryDescrption": "Performance Category",
						"CreditId": "CTP1PF904L-",
						"CreditDescription": "Transportation",
						"CreditStatus": "Attempted",
						"CreditShortId": "PF904",
						"PointsAvailable": "0",
						"Mandatory": "",
						"PointsAttempted": "0.0",
						"PointsAwarded": "0.0",
						"PersonAssigned": "",
						"FormVersion": "V01",
						"AssignedTo": "",
						"IvAttchPolicy": "",
						"IvReqFileupload": ""
					},
					{
						"CreditcategoryId": "CTP1PFL-",
						"CreditcategoryDescrption": "Performance Category",
						"CreditId": "CTP1PF905L-",
						"CreditDescription": "Human Experience",
						"CreditStatus": "Attempted",
						"CreditShortId": "PF905",
						"PointsAvailable": "0",
						"Mandatory": "",
						"PointsAttempted": "0.0",
						"PointsAwarded": "0.0",
						"PersonAssigned": "",
						"FormVersion": "V01",
						"AssignedTo": "",
						"IvAttchPolicy": "",
						"IvReqFileupload": ""
					},
					{
						"CreditcategoryId": "CTP1PFL-",
						"CreditcategoryDescrption": "Performance Category",
						"CreditId": "CTP1PF906L-",
						"CreditDescription": "Additional data",
						"CreditStatus": "Attempted",
						"CreditShortId": "PF906",
						"PointsAvailable": "0",
						"Mandatory": "",
						"PointsAttempted": "0.0",
						"PointsAwarded": "0.0",
						"PersonAssigned": "",
						"FormVersion": "V01",
						"AssignedTo": "",
						"IvAttchPolicy": "",
						"IvReqFileupload": ""
					}
				]
			});
			return;
		}
		
		forceCall = typeof forceCall !== 'undefined' ? forceCall : false;
		if (forceCall || !this.appData.get('all_actions').length)
		{
			setTimeout(() =>
			{
				Config.app_loader = true;
			})
			this.projects.getActionList(this.appData.get('project_type')).subscribe(data =>
			{
				this.appData.set('all_actions', data);
				this.appData.set('all_actions', this.insertAction(this.appData.get('buildingData').project_type, this.appData.get('all_actions').EtScorecardList));
				setTimeout(() =>
				{
					Config.app_loader = false;
				})
			},
			error =>
			{
				setTimeout(() =>
				{
					Config.app_loader = false;
				})
			});
		} 
	};

	checkInCurrentRatingSystem()
	{
		if (this.appData.get('project_rating_system')    == Config.config.settings.rating_systems.building
			|| this.appData.get('project_rating_system') == Config.config.settings.rating_systems.city
			|| this.appData.get('project_rating_system') == Config.config.settings.rating_systems.community
			|| this.appData.get('project_rating_system') == Config.config.settings.rating_systems.district
			|| this.appData.get('project_rating_system') == Config.config.settings.rating_systems.neighborhood
			|| this.appData.get('project_rating_system') == Config.config.settings.rating_systems.parksmart
			|| this.appData.get('project_rating_system') == Config.config.settings.rating_systems.transit
			|| this.appData.get('project_rating_system') == Config.config.settings.rating_systems.sites
			|| this.appData.get('project_rating_system') == 'LEED V4 BD+C: CT'
			|| this.appData.get('project_rating_system') == 'LEED V4 BD+C: CM'
			|| this.appData.get('project_rating_system') == 'LEED V4 BD+C: DT'
			|| this.appData.get('project_rating_system') == 'LEED V4 BD+C: NB'
			){
			return true;
		}
		else{
			return false;
		}
	}

	userAccessLevel(leed_id: number)
	{
		if(Config.access == null || this.appData.get('access') == undefined)
		{
			this.auth.getProjectTeam(leed_id).subscribe(data =>
			{
				// this.appData.set('access', 'CRUD');
				Config.access = 'CRUD';
				
				for(var i = 0; i < data.EtTeamMembers.length; i++)
				{
					if(data.EtTeamMembers[i].Useralias == this.appData.get('user_id').substring(0,40))
					{
						if(data.EtTeamMembers[i].Roleid == 'ARC01' || data.EtTeamMembers[i].Roleid == 'ARC02')
						{
							// this.appData.set('access', 'CRUD');
							Config.access = 'R';
						}
						if(data.EtTeamMembers[i].Roleid == 'ZRPO80' || data.EtTeamMembers[i].Roleid == 'ZRPO81' || data.EtTeamMembers[i].Roleid == 'ARC01' || data.EtTeamMembers[i].Roleid == 'ARC02')
						{
							data.EUserRole = data.EtTeamMembers[i].Roledescription;
							break;
						}
					}                    
				}

				Config.EUserRole = data.EUserRole;
				
				if(data.EUserRole == '')
				{
					// this.appData.set('access', 'R');
					Config.access = 'R';
				}
			});
		}
	};

	goToPayment(project: any, integration?: any)
	{
		if(Config.maintenance_banner)
		{
			this.showMaintenanceModal();
			return;
		}
		$('.trial-banner').slideUp();
		this.appData.set('leed_id', project.leed_id);
		this.appData.set('buildingData', project);
		this.appData.set('integration', integration);
		this.appData.set('payment_init', this.appData.get('current_app'));
		this.goToApp("project-payment", 
		{
			"leed_id": project.leed_id,
			"app_name": 'project-payment',
			'init': this.appData.get('current_app'),
			'integration': integration
		});
	}

	moveToProject(project: any, index: number | null, app_name?: string, searchIndex?: number)
	{
		this.appData.set('trial_version', false);
		this.appData.set('leed_id', project.leed_id);
		this.appData.set('buildingData', project);
		this.appData.set('project_type', project.project_type);
		this.appData.set('building_status', project.building_status);
		this.appData.set('cs_fullname', this.getState(project.state, project.country) + ", " + this.getCountry(project.country));
		this.appData.set('project_name', project.name);
		this.appData.set('project_rating_system', project.rating_system);
		this.appData.set('sign_addendum_agreement', false);
		this.appData.set('project_country', project.country);
		this.appData.set('subscription_interval', project.subscription_interval == 'year' ? false : true);
		this.appData.set("team_members", []);
		this.appData.set("new_arc_project", this.checkInCurrentRatingSystem());
		Config.score_type = String(project.leed_id)[0] == '1' ? 'weighted' : 'score';
		Config.agreement_project = project;
		Config.search_leed_id = project.leed_id;

		if(project.country == 'JP')
		{
			this.appData.set('currency', '¥');
			Config.currency = '¥';
			Config.price["arc_pro_payment_type"] = 'all';
		}
		else
		{
			this.appData.set('currency', '$');
			Config.currency = '$';
			Config.price["arc_pro_payment_type"] = 'yearly';
		}
		this.fetchPrice();
		this.getProjectInfo();
		
		if(project.project_type == "transit")
			this.appData.set('station_type', project.station_type);
		else
			this.appData.set('station_type', '');

		if(app_name == undefined)
		{
			app_name = "project-overview";
		}  

		if(this.appData.get('buildingData').building_status == 'setup_incomplete')
		{
			this.goToApp("project-setup", 
			{
				"leed_id": project.leed_id,
				"app_name": 'project-setup',
			});
		}
		else if(this.appData.get('buildingData').building_status == 'agreement_pending' || this.appData.get('buildingData').building_status == 'addendum_agreement_pending')
		{
			this.userAccessLevel(project.leed_id);
			this.appData.set('agreement_payment_pending', true);
			this.appData.set('project_details', {"sign_agreement": true});
			if (this.appData.get('buildingData').building_status == 'addendum_agreement_pending')
			{
				this.appData.set('sign_addendum_agreement', true);
			}

			if(index == null && searchIndex == null)
			{
				Config.redirect_to_landing_page_text = "Sign";
				Config.login_modal_text = "Please sign the agreement first.";
				Config.login_app_redirect = "agreements";
				$('.login_modal').modal('show');
			}
			else if(Config.selectedAgreementRow == undefined || String(Config.selectedAgreementRow) != String(project.leed_id))
			{
				Config.selectedAgreementRow = project.leed_id;
				
				if (index == null)
				{
					this.removeDynamicDiv();
					$('.search-row-' + searchIndex).parent().after(this.createDynamicDiv('search_agreement'));
				}
				else
				{
					this.removeDynamicDiv();
					$('.project-row-' + index).parent().after(this.createDynamicDiv('agreement'));
				}                    
			}
			else
			{
				Config.selectedAgreementRow = undefined;
				this.removeDynamicDiv();
			}
		}
		else if(this.appData.get('buildingData').building_status == 'activated_agreement_pending' || this.appData.get('buildingData').building_status == 'activated_addendum_agreement_pending')
		{
			this.userAccessLevel(project.leed_id);
			this.appData.set('agreement_payment_pending', true);
			this.appData.set('project_details', {"sign_agreement": true});
			if (this.appData.get('buildingData').building_status == 'activated_addendum_agreement_pending')
			{
				this.appData.set('sign_addendum_agreement', true);
			}

			Config.selectedAgreementRow = project.leed_id;
				
			if(index == null && searchIndex == null)
			{
				Config.redirect_to_landing_page_text = "Sign";
				Config.login_modal_text = "Please sign the agreement.";
				Config.login_app_redirect = "agreements";
				$('.login_modal').modal('show');
			}
			else if (index == null)
			{
				this.removeDynamicDiv();
				$('.search-row-' + searchIndex).parent().after(this.createDynamicDiv('search_agreement'));
			}
			else
			{
				this.removeDynamicDiv();
				$('.project-row-' + index).parent().after(this.createDynamicDiv('agreement'));
			} 
		}
		else if(this.appData.get('buildingData').building_status == 'activated_payment_pending' && !this.appData.get('buildingData').is_trial_selected)
		{
			this.userAccessLevel(project.leed_id);

			if(index == null && searchIndex == null)
			{
				Config.login_modal_text = "Please complete the project payment.";
				Config.login_app_redirect = "project-payment";
				$('.login_modal').modal('show');
			}
			else
			{
				if(app_name == "project-payment")
				{
					this.triggerReload();
				}
				else
				{
					$('.trial-banner').slideUp();
					this.appData.set('payment_init', this.appData.get('current_app'))
					this.goToApp("project-payment", 
					{
						"leed_id": project.leed_id,
						"app_name": 'project-payment',
						'init': this.appData.get('current_app')
					});
				}
			}
		}
		else if((this.appData.get('project_type') != 'building' && this.appData.get('project_type') != 'school') && project.is_trial_selected && moment(new Date()).isAfter(project.trial_expire_date) && this.appData.get('buildingData').building_status == 'activated_payment_pending')
		{
			// trial expired
			this.getActionList(true);
			this.trialAccessModal();
			this.appData.set('trial_version', null);

			$('.project_row').removeClass('not-active');
			if(this.appData.get('current_app') == 'projects')
			{
				this.goToApp('projects');
			}
			Config.app_loader = false;
			return;

		}
		else if (this.appData.get('building_status') == 'activated_payment_done'  || this.appData.get('building_status') == 'activated_under_review' || this.trialStatus(this.appData.get('buildingData')))
		{    
			Config.app_loader = true;
			this.getActionList(true);
			this.getMaxima();
			this.installedApps();
			if(index == null && searchIndex == null && this.appData.get('new_arc_project'))
			{
				this.projects.getActionList(this.appData.get('project_type')).subscribe(data =>
				{
					this.appData.set('all_actions', data);
					for(var i = 0; i < data.length; i++)
					{
						if(data[i].CreditShortId == Config.CreditShortId)
						{
							this.appData.set('credit', data[i]);
							break;
						}
					}
				});
			}

			if(app_name == 'project-payment')
			{
				app_name = 'actions'
			}
			
			if((this.appData.get('project_type') != 'building' && this.appData.get('project_type') != 'school') && moment(new Date()).isAfter(project.trial_expire_date) && project.is_trial_selected)
			{
				// trial expired
				Config.app_loader = false;
				this.trialAccessModal();
				this.appData.set('trial_version', null);
				Config.accept_agreement_flag = false;
				$('.project_row').removeClass('not-active');
				if(this.appData.get('current_app') != 'projects')
				{
					this.goToApp('projects');
				}
				return;
			}
			else if((this.appData.get('project_type') != 'building' && this.appData.get('project_type') != 'school') && moment(new Date()).isBefore(project.trial_expire_date) && project.is_trial_selected && project.activation_source != 'GRESB')
			{
				Config.app_loader = false;
				this.appData.set('trial_version', true);
				$('.trial-banner').slideDown();
				if(app_name != 'project' && app_name != 'score-version' && app_name != 'total-score' && app_name != 'base-score' && app_name != 'energy-score' && app_name != 'water-score' && app_name != 'waste-score' && app_name != 'transportation-score' && app_name != 'humanexperience-score')
				{
					if(this.appData.get('project_type') == 'city' || this.appData.get('project_type') == 'community')
					{
						this.goToApp("selected_project", 
						{
							"leed_id": project.leed_id,
							"app_name": 'project-performance'
						});
					}
					else
					{
						this.goToApp("selected_project", 
						{
							"leed_id": project.leed_id,
							"app_name": 'data-input'
						});
					}
					Config.access = 'CRUD';
					this.fullAccessModal();
				}
				return;
			}

			this.auth.getProjectTeam(project.leed_id).subscribe(data =>
			{
				Config.access = 'R';
				Config.EUserRole = data.EUserRole;
				this.appData.set('all_actions', data.EtTeamMembers);
				var found = false;
				var user_removed = false;
				for(var i = 0; i < data.EtTeamMembers.length; i++)
				{
					if(data.EtTeamMembers[i].Useralias == this.appData.get('user_id').substring(0,40))
					{
						var role_access = 'CRUD';
						if(data.EtTeamMembers[i].Roleid == 'ARC01' || data.EtTeamMembers[i].Roleid == 'ARC02')
						{
							role_access = 'R';
						}
						if(data.EtTeamMembers[i].Roleid == 'ZRPO80' || data.EtTeamMembers[i].Roleid == 'ZRPO81' || data.EtTeamMembers[i].Roleid == 'ARC01' || data.EtTeamMembers[i].Roleid == 'ARC02')
						{
							if(data.EtTeamMembers[i].Responsibility == 'D')
							{
								user_removed = true;
							}
							else
							{
								user_removed = false;
								Config.access = role_access;
								break;
							}
						}
						found = true;
					}   
				}

				if(user_removed)
				{
					this.alertService.alert('error', 'Sorry! You don\'t have access to this project.', 5);
					return;
				}

				if(Config.access == 'R' && found == true && !this.appData.get('is_admin'))
				{
					this.api.get(
						'/assets/LEED:' + project.leed_id + '/permissions/requests/'
					).subscribe(data =>
					{
						var found = false;
						var request = data[0];

						for(var a = 1; a < data.length; a++)
						{
							if(moment(data[a].updated_at) > request.updated_at)
							{
								request = data[a];
							}
						}

						if(data.length == 0)
						{
							if (index == null)
							{
								this.removeDynamicDiv();
								$('.search-row-' + searchIndex).parent().after(this.createDynamicDiv('search_permission', index, searchIndex));
								return;
							}
							else
							{
								this.removeDynamicDiv();
								$('.project-row-' + index).parent().after(this.createDynamicDiv('permission', index, searchIndex));
								return;
							}
						}
						else if(request.is_seen == false)
						{
							found = true;

							if(index == null)
							{
								this.removeDynamicDiv();
								$('.search-row-' + searchIndex).parent().after(this.createDynamicDiv('search_wait_permission', index, searchIndex));
							}
							else
							{
								this.removeDynamicDiv();
								$('.project-row-' + index).parent().after(this.createDynamicDiv('wait_permission', index, searchIndex));
							}
						}
						else if(request.is_seen == true && request.is_granted == false)
						{
							if(index == null)
							{
								this.removeDynamicDiv();
								$('.search-row-' + searchIndex).parent().after(this.createDynamicDiv('search_deny_permission', index, searchIndex));
							}
							else
							{
								this.removeDynamicDiv();
								$('.project-row-' + index).parent().after(this.createDynamicDiv('deny_permission', index, searchIndex));
							}
						}
					});
				}
				else
				{
					// Initialize score code version variables
					if (document.URL.indexOf("/manage/scoreversion") < 0){
						this.initScoreCodeVersion(false);
					}
					this.showUserScoreVersionUpdateNotification();

					Config.app_loader = false;
					$('.trial-banner').slideUp();
					if(this.appData.get('new_arc_project'))
					{
						$("#all_actions").removeClass("hide_all_actions_style");
						$("#credits_actions").parent().addClass("active_class_aa");
						$("#data_input").parent().removeClass("active_class_di");
						$("#all_actions").parent().addClass("active_class_aa");
						if(this.appData.get('project_type') == "parksmart")
						{
							this.goToApp("selected_project", 
							{
								"leed_id": project.leed_id,
								"app_name": 'measures'
							});
						}
						else
						{
							if(index == null && searchIndex == null)
							{
								this.goToApp("selected_project", 
								{
									"leed_id": project.leed_id,
									"app_name": app_name
								});
							}
							else
							{
								try
								{
									if(this.appData.get('buildingData').first_app_visited == 'allactions')
									{
										if(this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school')
										{
											this.goToApp("selected_project", 
											{
												"leed_id": project.leed_id,
												"app_name": 'project-overview'
											});
										}
										else if(this.appData.get('project_type') == 'city' || this.appData.get('project_type') == 'community')
										{
											this.goToApp("selected_project", 
											{
												"leed_id": project.leed_id,
												"app_name": 'project-performance'
											});
										}
										else 
										{
											this.goToApp("selected_project", 
											{
												"leed_id": project.leed_id,
												"app_name": 'data-input'
											});
										}
									}
									else
									{
										this.goToApp("selected_project", 
										{
											"leed_id": project.leed_id,
											"app_name": app_name
										});
									}
								}
								catch(e)
								{
									if (this.isLEEDCertified(this.appData.get('buildingData').certification))
									{
										this.goToApp("selected_project", 
										{
											"leed_id": project.leed_id,
											"app_name": 'data-input'
										});
									}
									else
									{
										this.goToApp("selected_project", 
										{
											"leed_id": project.leed_id,
											"app_name": 'all-actions'
										});
									}
								}
							}
						}
					}
					else
					{
						$("#credits_actions").parent().addClass("active_class_di");
						$("#credits_actions").parent().removeClass("active_class_aa");
						$("#data_input").parent().addClass("active_class_di");
						if(this.appData.get('project_type') == "parksmart")
						{
							this.goToApp("selected_project", 
							{
								"leed_id": project.leed_id,
								"app_name": 'measures'
							});
						}
						else
						{
							if(index == null && searchIndex == null)
							{
								if(app_name == "actions")
								{
									if(this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school')
									{
										app_name = "project-overview";
									}
									else if(this.appData.get('project_type') == 'city' || this.appData.get('project_type') == 'community')
									{
										app_name = "project-performance";
									}
									else 
									{
										app_name = "data-input";
									}
								}

								this.goToApp("selected_project", 
								{
									"leed_id": project.leed_id,
									"app_name": app_name
								});
							}
							else
							{
								try
								{
									if(this.appData.get('buildingData').first_app_visited == 'allactions')
									{
										if(this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school')
										{
											this.goToApp("selected_project", 
											{
												"leed_id": project.leed_id,
												"app_name": 'project-overview'
											});
										}
										else if(this.appData.get('project_type') == 'city' || this.appData.get('project_type') == 'community')
										{
											this.goToApp("selected_project", 
											{
												"leed_id": project.leed_id,
												"app_name": 'project-performance'
											});
										}
										else
										{
											this.goToApp("selected_project", 
											{
												"leed_id": project.leed_id,
												"app_name": 'data-input'
											});
										}
									}
									else
									{
										this.goToApp("selected_project", 
										{
											"leed_id": project.leed_id,
											"app_name": app_name
										});
									}
								}
								catch(e)
								{
									if(this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school')
									{
										this.goToApp("selected_project", 
										{
											"leed_id": project.leed_id,
											"app_name": 'project-overview'
										});
									}
									else if(this.appData.get('project_type') == 'city' || this.appData.get('project_type') == 'community')
									{
										this.goToApp("selected_project", 
										{
											"leed_id": project.leed_id,
											"app_name": 'project-performance'
										});
									}
									else
									{
										this.goToApp("selected_project", 
										{
											"leed_id": project.leed_id,
											"app_name": 'data-input'
										});
									}
								}
							}
							
						}
					}
					$("#credits_actions").removeClass("collapsed");
					$("#sidebar-actions").addClass("in");
					$("#sidebar-actions").attr("aria-expanded", "true");
					this.searchDisable();

				}
				Config.app_loader = false;
			});
		} 
	}

	formatNumber(number: number ,decimals: number , showNA?: boolean)
	{
		if((number == null || number == undefined) && showNA)
		{
			return 'NA'
		}

		if(isNaN(number))
		{
			number = 0
		}
		
		let amt: any = parseFloat(String(number));
		amt = amt.toFixed(decimals);
		if(isNaN(amt) || String(amt) == 'Infinity' || String(amt) == '-Infinity')
		{
			return '0'
		}
		else
		{
			return amt
		}
	};

	fetchPrice()
	{
		this.api.get(
			'/price/'
		).subscribe(data => 
		{
			if(this.appData.get('currency') == '$' || this.appData.get('currency') == undefined)
			{
				var cr = 'USD';
				Config.currency = '$';
				Config.price["arc_pro_payment_type"] = 'all';
			}
			else
			{
				var cr = 'YEN';
				Config.currency = '¥';
				Config.price["arc_pro_payment_type"] = 'yearly';
			}
			for(var i in data)
			{
				if(data[i].soreference == 'premium' && data[i].period == 'monthly' && data[i].currency == cr)
				{
					Config.price["arc_pro_monthly"] = this.formatNumber(data[i].price_per_unit, 2);
				}
				else if(data[i].soreference == 'premium' && data[i].period == 'annually' && data[i].currency == cr)
				{
					Config.price["arc_pro_annually"] = this.formatNumber(data[i].price_per_unit, 2);
				}
				else if(data[i].soreference == 'performance' && data[i].period == 'monthly')
				{
					Config.price["certificate_monthly"] = this.formatNumber(data[i].price_per_unit, 2);
				}
				else if(data[i].soreference == 'performance' && data[i].period == 'annually' && data[i].currency == cr)
				{
					Config.price["certificate_annually"] = this.formatNumber(data[i].price, 2);
				}
				else if(data[i].soreference == 'climaterisk' && data[i].currency == cr)
				{
					Config.price["climaterisk_price"] = data[i].price;
				}
			}
		});
	}; 
	
	getPrice(type: any)
	{
		return Config.price[type];
	}

	trialStatus(project?: any)
	{
		if(project == undefined)
		{
			project = this.appData.get('buildingData');
		}
		var is_trial = false;
		if(project.is_trial_selected && project.trial_expire_date)
		{
			if(moment(new Date()).isBefore(project.trial_expire_date))
			{
				is_trial = true;
			}
			else
			{
				is_trial = false;
			}
		}
		return is_trial;
	}

	getMaxima()
	{
		this.appData.set('maxima', {
			"base": 0,
			"energy": 0,
			"water": 0,
			"waste": 0,
			"transport": 0,
			"human_experience": 0,
			"subscores_source": 0,
			"subscores_carbon": 0,
			"subscores_occupant": 0,
			"subscores_co2":0,
			"subscores_voc":0
		});
		this.api.get('/assets/LEED:' + this.appData.get('leed_id') + '/scores/weights/').subscribe(
			data =>
			{
				this.appData.set('maxima', data);
			},
			function(error)
			{
				
			}
		);
	}

	getPortFolios()
	{
		this.projects.getPortfolios().subscribe(data =>
		{
			this.appData.set('portfolios', data.results);
			if(data.next == null) 
			{
				Config.nextPagePortfolios = null;
			} else
			{
				Config.nextPagePortfolios = data.next.split('?')[data.next.split('?').length - 1];
			}
		});
	}

	openEmail(email: string)
	{
		window.open('mailto:'+ email, '_self');
	}

	checkUserStatus()
	{
		Config.app_loader = true;
		this.api.get(
			'/auth/user/subscriptions/'
		).subscribe(
			response =>
			{
				var subscriptions = response;
				if(subscriptions.subscribers == undefined)
				{
					subscriptions = {
						"subscribers": []
					}
				}
				
				var user_in_subs_list = false;
				for(var i = 0; i < subscriptions.subscribers.length; i++)
				{
					if(subscriptions.subscribers[i].email == this.appData.get('user_id'))
					{
						user_in_subs_list = true;
						break;
					}
				}
				if(subscriptions.subscribed_by != undefined && subscriptions.subscribed_by.username == this.appData.get('user_id') && !user_in_subs_list && subscriptions.subscribers.length > 0)
				{
					this.upgrade("add_subscription", undefined, subscriptions);
				}
				else
				{
					this.upgrade("purchase_arc_pro", undefined);
				}

				Config.app_loader = false;
			},
			function(response)
			{
				Config.app_loader = false;
			}
		);
	}

	filterAccess(app: string, payload?: any, query_param?: any)
	{
		if(app == 'app.arc_pro_purchase')
		{
			app = 'upgrade_arc';
			payload = undefined;
			query_param = {'mode': 'purchase_arc_pro'};
		}
		if(Config.maintenance_banner && (Config.maintenance_apps.indexOf(app) != -1))
		{
			this.showMaintenanceModal();
			return;
		}
		if(app == 'app.arc_pro_purchase' && (payload == undefined || payload["mode"] == 'purchase_arc_pro'))
		{
			this.checkUserStatus();
			return;
		}
		else if(app == 'app.homeOverview' && this.appData.get('is_admin'))
		{
			$('.home_admin_block').modal('show');
			return;
		}
		if(payload == undefined && (app == 'app.arc_pro_purchase' || app == 'upgrade_arc'))
		{
			this.upgradeUser('purchase_arc_pro');
		}
		else
		{
			this.goToApp(app, payload, query_param);
		}
	}

	showCertDetails(project: any)
	{
		let modalRef = this.modalService.open(SideBar.CertDetails, {windowClass: 'sideBar--projectsDetailsModal'});
		modalRef.componentInstance.project = project;
	}

	setCookie(name: any,value: any,days: any)
	{
		var expires = "";
		if (days)
		{
			var date = new Date();
			date.setTime(date.getTime() + (days*24*60*60*1000));
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + (value || "")  + expires + "; path=/";
	};
	
	getCookie(name: any)
	{
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++)
		{
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	};
	
	eraseCookie(name: any)
	{   
		document.cookie = name+'=; Max-Age=-99999999;';  
	};

	logoutConfirm()
	{
		if(this.appData.getLocal('optional_logout') == true)
		{
			this.logout();
		}
		else
		{
			$('.logout_confirmation').modal('show');
		}
	}

	logout(url?: string)
	{
		Config.app_loader = true;
		$('.logout_confirmation').modal('hide');
		if(url == undefined)
		{
			url = Config.landingPageURL;
		}
		this.auth0.logout({ 
			logoutParams: {
				returnTo: url
			}
		});
		this.appData.deleteLocal('jwt');
        this.appData.deleteLocal('auth0');
		this.setCookie('user_list', JSON.stringify([]), 1);
		// this.api.get('/auth/logout/').subscribe(
		// 	//success handler
		// 	data =>
		// 	{
		// 		this.appData.set('insight_session', undefined);
		// 		if(this.appData.get('user_type') == 'arc_japan' && Config.config.settings.logout_url_jp != '')
		// 		{
		// 			location.href = Config.config.settings.logout_url_jp;
		// 		}
		// 		else
		// 		{
		// 			location.href = Config.config.settings.logout_url;
		// 		}
		// 	},
		// 	//error handler
		// 	function(response)
		// 	{
				
		// 	}
		// );
	}

	getAgreementUrl(): any
	{
		if(this.appData.get('buildingData') == undefined)
		{
			return;
		}
		var addendum_pdf_url = '';
		var project_certification = this.appData.get('buildingData').certification;
		
		if (project_certification != undefined && project_certification != null && this.isLEEDCertified(project_certification))
		{
			addendum_pdf_url = Config.config.settings.addendum_pdf;
		}
		else if (!(this.checkInCurrentRatingSystem() || this.appData.get('buildingData').rating_system.toLowerCase() == 'none' || this.appData.get('buildingData').rating_system.toLowerCase() == 'other'))
		{
			addendum_pdf_url = Config.config.settings.addendum_pdf;
		}
		else
		{
			if (this.appData.get('buildingData').project_type.toLowerCase() == 'parksmart')
			{
				addendum_pdf_url = Config.config.settings.parksmart_registration_pdf;
			}
			else if (this.appData.get('buildingData').project_type.toLowerCase() == 'transit')
			{
				addendum_pdf_url = Config.config.settings.addendum_pdf;
			}
			else
			{
				addendum_pdf_url =  Config.config.settings.registration_pdf;
			}
		}
		return addendum_pdf_url;
	}

	acceptAgreement(from_search?: boolean, project?: any)
	{
		Config.app_loader = true;
		from_search = typeof from_search !== 'undefined' ? from_search : false;

		this.auth.getAuth().subscribe(
		    //success handler
		    data =>
		    {
				let user_number = data.EPartner, SoReference;
		        if (this.appData.get('sign_addendum_agreement'))
		        {
		            SoReference = 'ADDENDUM';
		        }
		        Config.login_modal_button_flag = true;
		        $('.agreement_button').text("Processing...");
		        Config.accept_agreement_flag = true;
		        $('.agreement_button').addClass('not-active');

		        $('#agreement_iframe').contents().find('#signer_name').html(this.appData.get('username'));
		        $('#agreement_iframe').contents().find('#signer_id').html(this.appData.get('user_id'));
		        $('#agreement_iframe').contents().find('#user_number').html(user_number);
		        if(from_search)
		        {
		            $('#agreement_iframe').contents().find('#name').html(Config.search_data.name);
		            $('#agreement_iframe').contents().find('#owner_name').html(Config.search_data.owner_email);
		        }
		        else
		        {
		            $('#agreement_iframe').contents().find('#name').html(this.appData.get('buildingData').name);
		            $('#agreement_iframe').contents().find('#owner_name').html(this.appData.get('buildingData').owner_email);
		        }
		        $('#agreement_iframe').contents().find('#user_number').append('<p><span><b>Project ID</b>                  : <span id="leed_id">'+ this.appData.get('leed_id') +'</span></span></p>');
				
		        var date_time = moment(new Date()).format("MMM DD, YYYY [at] HH:mm:ss ") +  moment().tz(moment.tz.guess()).zoneAbbr();
				
		        $('#agreement_iframe').contents().find('#date_time').html(date_time);
				
		        var mimeType = 'text/html';
		        var form_data = new FormData();
				
		        if(this.getBrowser().ie == true || this.getBrowser().safari == true)
		        {
		            var file: any = new Blob([$('#agreement_iframe').contents().find('html').html()], { type: mimeType});
		            file.lastModifiedDate = new Date();
		            form_data.append('agreement', file, "agreement.html");
		        }
		        else
		        {
		            var file: any = new File([$('#agreement_iframe').contents().find('html').html()], "agreement.html", {type: mimeType, lastModified: (<any>new Date())});
		            form_data.append('agreement', file);
		        }
				
		        var agreement_post: any = 
		        {
		            'signer_name': this.appData.get('username'),
		            'signer_id': this.appData.get('user_id'),
		            'date_time': new Date(),
		            "SoReference": SoReference
		        }

		        for (var key in agreement_post){
		            if (agreement_post.hasOwnProperty(key)) {
		                form_data.append(key, agreement_post[key]);
		            }
		        }

		        var custom_basic_config_header = 
		        {
		            headers: 
		            {
		                "responseType": "arraybuffer",
		                "Ocp-Apim-Subscription-Key": Config.sub_key,
		                "Authorization": "Bearer " + this.appData.get('token')
		            }
		        };

		        this.api.post('/assets/LEED:' + Config.search_leed_id + '/agreements/', form_data, custom_basic_config_header).subscribe(
		            //success handler
		            response =>
		            {
		                this.api.get('/assets/LEED:' + Config.search_leed_id + '/').subscribe(
		                    project_response =>
		                    {
								Config.app_loader = false;
		                        $('.login_modal').modal('hide');
		                        var data = {
		                            "user_email": this.appData.get('user_id'),
		                            "Reltyp": "ZRPO80"
		                        };                 
								
		                        if (Config.agreement_project.building_status == 'agreement_pending' || Config.agreement_project.building_status == 'addendum_agreement_pending')
								{
									Config.agreement_project.building_status = 'activated_payment_pending';
								}
								else
								{
									Config.agreement_project.building_status = 'activated_payment_done';
								}
								Config.agreement_project.trial_expire_date = project_response.trial_expire_date;
								Config.agreement_project.trial_start_date = project_response.trial_start_date;

								Config.agreement_portfolio["is_gresb_activated"] = project_response.activation_source == 'GRESB';
                                Config.agreement_portfolio["is_gresb"] = project_response.activation_source == 'GRESB';

		                        if(!from_search)
		                        {
		                            this.appData.set('buildingData', project_response);  
		                            if (this.appData.get('buildingData').building_status == 'agreement_pending' || this.appData.get('buildingData').building_status == 'addendum_agreement_pending'){
		                                this.appData.get('buildingData').building_status = 'activated_payment_pending';
		                            }
		                            else{
		                                this.appData.get('buildingData').building_status = 'activated_payment_done';
		                            }
		                        }

		                        $('.agreement_button').text("Accept");
								Config.accept_agreement_flag = false;
								$('.agreement_button').removeClass('not-active');
								Config.login_modal_button_flag = false;
								this.removeDynamicDiv();
								this.alertService.alert('success', 'Agreement signed successfully.', 5);

								if(window.location.href.indexOf('login_middleware') != -1)
								{
									this.goToApp('login_middleware');
								}
		                    }
		                );
		            },
		            //error handler
		            response =>
					{
						Config.app_loader = false;
		                $('.agreement_button').text("Accept");
		                Config.accept_agreement_flag = false;
		                $('.agreement_button').removeClass('not-active');
		                Config.login_modal_button_flag = false;
		                if (response.detail != undefined){
		                    this.alertService.alert('error', response.detail, 5);
		                }
		                else{
		                    this.alertService.alert('error', 'Something went wrong. Try again.', 5);
		                }
		            }
		        );
		    }
		);
	}

	sendRequest()
	{
		Config.app_loader = true;
		let payload = {"user_name": this.appData.get('user_id')};
		
		this.api.post('/assets/LEED:' + Config.agreement_project.leed_id + '/permissions/requests/', payload).subscribe(
			//success handler
			data =>
			{
				Config.app_loader = false;
				this.removeDynamicDiv();
			},
			//error handler
			error =>
			{
				Config.app_loader = false;
			}   
		);
	}

	downloadPendingReviewReceipt()
	{
		this.anchorDownload("Reciept("+ this.appData.get('review_pending_invoice').order_number +").pdf", Config.basic_api_url + "/assets/LEED:"+ this.appData.get('leed_id') +"/payments/receipt/"+ this.appData.get('review_pending_invoice').order_number +"/?access-token="+ this.appData.get('token') +"&subscription-key="+ Config.sub_key);
	}

	moveToPayNow()
	{
		this.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'paynow', 'invoice_number': this.appData.get('review_pending_invoice').order_number});
	}

	createDynamicDiv(type: string, index?: number | null, searchIndex?: number)
	{
		let div = "";   
		if (index == null)
		{
			index = searchIndex;
		}

		Config.dynamic_row = type.replace("search_", "");
		$('.dynamic_div').modal('show');
		
		// if (type=='agreement')
		// {
		// 	div += "<tr class='project_agreement_row'>";
		// 		div += "<td colspan='6' class='vertical_align_middle text_row'>";
		// 		div += "I accept the terms and conditions of <span (click)='openLink(getAgreementUrl(), true)' class='agreement_tag'>service agreement</span>";
		// 		div += "</td>";
		// 		div += "<td colspan='1' class='vertical_align_middle button_row'>";
		// 		div += "<button index="+ index +" ng-class=\"access == 'R' ? 'not-active' : ''\" class='agreement_button btn btn-primary btn-sm' ng-disabled='accept_agreement_flag' (click)='acceptAgreement()'>Accept</button>&nbsp;&nbsp;";
		// 		div += "</td>";
		// 	div += "</tr>";
		// }
		// else if(type=='search_agreement')
		// {
		// 	div += "<tr class='project_agreement_row' style='height:60px;'>";
		// 		div += "<td colspan='2' class='vertical_align_middle' style='width: 50%;'>";
		// 		div += "I accept the terms and conditions of <span (click)='openLink(getAgreementUrl(), true)' class='agreement_tag'>service agreement</span>";
		// 		div += "</td>";
		// 		div += "<td class='vertical_align_middle align-right'>";
		// 		div += "<button index="+ index +" ng-class=\"access == 'R' ? 'not-active' : ''\" type='button' class='agreement_button btn btn-primary btn-xs' ng-disabled='accept_agreement_flag' (click)='acceptAgreement()'>Accept</button>&nbsp;&nbsp;";
		// 		div += "</td>";
		// 	div += "</tr>";
		// }
		// else if (type=='permission')
		// {
		// 	div += "<tr class='project_agreement_row'>";
		// 		div += "<td colspan='6' class='vertical_align_middle text_row'>";
		// 		div += "Please contact the Arc Project Admin for access to this project.";
		// 		div += "</td>";
		// 		div += "<td colspan='1' class='vertical_align_middle button_row'>";
		// 		div += "<button index="+ index +" class='agreement_button btn btn-primary btn-sm button-"+ index +" accept-button-"+ index +"' (click)='sendRequest()'>Request Access</button>&nbsp;&nbsp;";
		// 		div += "<button class='btn btn-default btn-sm button-"+ index +"' (click)=\"removeDynamicDiv()\">Ok</button>";
		// 		div += "</td>";
		// 	div += "</tr>";
		// }
		// else if(type=='search_permission')
		// {
		// 	div += "<tr class='project_agreement_row' style='height:60px;'>";
		// 	div += "<td class='vertical_align_middle' style='width: 50%;'>";
		// 	div += "Please contact the Arc Project Admin for access to this project.";
		// 	div += "</td>";
		// 	div += "<td class='vertical_align_middle'>";
		// 	div += "<button index="+ index +" class='agreement_button btn btn-primary btn-xs button-"+ index +" accept-button-"+ index +"' (click)='sendRequest("+ index +")'>Request Access</button>&nbsp;&nbsp;";
		// 	div += "</td>";
		// 	div += "<td class='vertical_align_middle'>";
		// 	div += "<button class='search_button btn btn-default btn-xs button-"+ index +"' (click)='removeDynamicDiv()'>Ok</button>";
		// 	div += "</td>";
		// 	div += "</tr>";
		// }
		// else if (type=='wait_permission')
		// {
		// 	div += "<tr class='project_agreement_row'>";
		// 		div += "<td colspan='6' class='vertical_align_middle text_row'>";
		// 		div += "Your request to access this project is pending with the Project Admin.";
		// 		div += "</td>";
		// 		div += "<td colspan='1' class='vertical_align_middle button_row'>";
		// 		div += "<button class='btn btn-default btn-sm button-"+ index +"' (click)='removeDynamicDiv()'>Ok</button>";
		// 		div += "</td>";
		// 	div += "</tr>";
		// }
		// else if(type=='search_wait_permission')
		// {
		// 	div += "<tr class='project_agreement_row' style='height:60px;'>";
		// 	div += "<td class='vertical_align_middle' style='width: 50%;'>";
		// 	div += "Your request to access this project is pending with the Project Admin.";
		// 	div += "</td>";
		// 	div += "<td class='vertical_align_middle'>";
		// 	div += "</td>";
		// 	div += "<td class='vertical_align_middle'>";
		// 	div += "<button class='search_button btn btn-default btn-xs button-"+ index +"' (click)='removeDynamicDiv()'>Ok</button>";
		// 	div += "</td>";
		// 	div += "</tr>";
		// }
		// else if (type=='deny_permission')
		// {
		// 	div += "<tr class='project_agreement_row'>";
		// 	div += "<td>";
		// 	div += "</td>";
		// 	div += "<td class='vertical_align_middle text_row'>";
		// 	div += "Your request to access this project was denied by the Project Admin.";
		// 	div += "</td>";
		// 	div += "<td class='vertical_align_middle button_row'>";
		// 	div += "<button class='btn btn-default btn-sm button-"+ index +"' (click)='removeDynamicDiv()'>Ok</button>";
		// 	div += "</td>";
		// 	div += "</tr>";
		// }
		// else if(type=='search_deny_permission')
		// {
		// 	div += "<tr class='project_agreement_row' style='height:60px;'>";
		// 	div += "<td class='vertical_align_middle' style='width: 50%;'>";
		// 	div += "Your request to access this project was denied by the Project Admin.";
		// 	div += "</td>";
		// 	div += "<td class='vertical_align_middle'>";
		// 	div += "</td>";
		// 	div += "<td class='vertical_align_middle'>";
		// 	div += "<button class='search_button btn btn-default btn-xs button-"+ index +"' (click)='removeDynamicDiv()'>Ok</button>";
		// 	div += "</td>";
		// 	div += "</tr>";
		// }
		// else if (type=='review_payment_pending')
		// {
		// 	div += "<tr class='project_agreement_row'>";
		// 	div += "<td colspan='2' class='vertical_align_middle text_row'>";
		// 	div += "An outstanding annual payment of $"
		// 	div += "<span class='next_payment_amount_" + index + "'></span>"
		// 	div += " was due on "
		// 	div += "<span class='next_payment_date_" + index + "'></span>"
		// 	div += ". Please email <a (click)=\"openEmail('contact@arcskoru.com')\">contact@arcskoru.com</a> with any questions."
		// 	div += "</td>";
		// 	div += "<td colspan='1' class='vertical_align_middle button_row'>";
		// 	div += "<button index="+ index +" ng-class=\"access == 'R' ? 'not-active' : ''\" class='btn btn-default btn-sm button-"+ index +"' (click)='downloadPendingReviewReceipt()'><i class='fa fa-download' aria-hidden='true'></i> Invoice</button>";
		// 	div += "</td>";
		// 	div += "<td colspan='1' class='vertical_align_middle button_row'>";
		// 	if (this.appData.get('buildingData').country != 'IN')
		// 	{
		// 		div += "<button ng-class=\"access == 'R' ? 'not-active' : ''\" class='btn btn-default btn-sm button-"+ index +"' (click)='moveToPayNow()'>Pay now</button>";
		// 	}
		// 	div += "</td>";
		// 	div += "</tr>";
		// }
		// else if(type=='search_review_payment_pending')
		// {
		// 	div += "<tr class='project_agreement_row' style='height:60px;'>";
		// 	div += "<td colspan='2' class='vertical_align_middle text_row fs12 w60p'>";
		// 	div += "Annual payment of $"
		// 	div += "<span class='search_next_payment_amount_" + searchIndex + "'></span>"
		// 	div += " was due on "
		// 	div += "<span class='search_next_payment_date_" + searchIndex + "'></span>"
		// 	div += ". Please email <a (click)=\"openEmail('contact@arcskoru.com')\">contact@arcskoru.com</a> with any questions."
		// 	div += "</td>";
		// 	div += "<td colspan='2' class='vertical_align_middle'>";
		// 	div += "<button index="+ index +" ng-class=\"access == 'R' ? 'not-active' : ''\" class='search_button btn btn-default btn-xs button-"+ index +"' (click)='downloadPendingReviewReceipt()'><i class='fa fa-download mr2' aria-hidden='true'></i> Invoice</button>";
		// 	if (this.appData.get('buildingData').country != 'IN')
		// 	{
		// 		div += "<button ng-class=\"access == 'R' ? 'not-active' : ''\" class='ml20 search_button btn btn-default btn-xs button-"+ index +"' (click)='moveToPayNow()'>Pay now</button>";
		// 	}
		// 	div += "</td>";
		// 	div += "</tr>";
		// }
		// // var compiled_div = $compile(div)($scope);
		
		return ""
	}

	removeDynamicDiv()
	{
		// $('.project_agreement_row').remove();
		$('.dynamic_div').modal('hide');
	}

	searchMoveToProject(project: any, index: number | null, next_app?: string, state_data?: any, query_params?: any)
	{
		if(project.leed_id == null)
		{
			return;
		}
		if(this.appData.get('LO_referer'))
		{
			this.appData.set('LO_refered_id', project.leed_id);
		}
		Config.app_loader = true;
		$('.searchDropdown').show();
		Config.search_leed_id = project.leed_id;
		Config.agreement_project = project;
		this.api.get(
			'/assets/LEED:' + project.leed_id + '/'
		).subscribe(data =>
		{
			Config.search_data = data;
			if (data.building_status == 'agreement_pending' || data.building_status == 'addendum_agreement_pending')
			{
				this.userAccessLevel(Config.search_leed_id);
				if (data.building_status == 'addendum_agreement_pending')
				{
					this.appData.set('sign_addendum_agreement', true)
				}

				if (Config.selectedAgreementRow == undefined || String(Config.selectedAgreementRow) != String(project.leed_id))
				{
					Config.selectedAgreementRow = project.leed_id;
					this.removeDynamicDiv();
					$('.search-row-' + index).parent().after(this.createDynamicDiv('search_agreement'));
				}
				else
				{
					Config.selectedAgreementRow = undefined;
					this.removeDynamicDiv();
				}
				Config.app_loader = false;

			}
			else if (data.building_status == 'activated_agreement_pending' || data.building_status == 'activated_addendum_agreement_pending')
			{
				this.userAccessLevel(Config.search_leed_id);
				if (data.building_status == 'activated_addendum_agreement_pending')
				{
					this.appData.set('sign_addendum_agreement', true)
				}

				if (Config.selectedAgreementRow == undefined || String(Config.selectedAgreementRow) != String(project.leed_id))
				{
					Config.selectedAgreementRow = project.leed_id;
					this.removeDynamicDiv();
					$('.search-row-' + index).parent().after(this.createDynamicDiv('search_agreement'));
				}
				else
				{
					Config.selectedAgreementRow = undefined;
					this.removeDynamicDiv();
				}
				Config.app_loader = false;
			}
			else
			{
				Config.app_loader = false;
				this.moveToProject(data, index, next_app);
			}
		},
		data =>
		{
			Config.app_loader = false;
			try
			{
				if(data.error.detail == 'Not found.')
				{
					$('.token_incorrect_modal').modal('show');
				}
				else if(data.status == 403 || data.status == 404)
				{
					this.alertService.alert('error', "You don't have access to project: " + project.leed_id);
					this.goToApp('home');
				}
			}
			catch(e)
			{
				this.goToApp('home');
			}
		});
	}

	searchMoveToPortfolio(pf_id: any, state_name?: any)
	{
		Config.app_loader = true;
		this.api.get('/portfolios/ID:' + pf_id).subscribe(
			//success handler
			response =>
			{
				Config.app_loader = false;
				this.moveToPortfolio(response.name, response.pf_id, response, state_name);
			},
			//error handler
			error =>
			{
				Config.app_loader = false;
				if(error.status == 401)
				{
					return;
				}
				$('.token_incorrect_modal').modal('show');
			}
		);
	}

	getPortfolioTeam()
	{
		Config.app_loader = true;
		this.api.get(
			'/portfolios/ID:' + this.appData.get('portfolio_id') + '/teams/'
		).subscribe(
			data =>
			{
				Config.app_loader = false;
				var found = false;
				for(var i = 0; i < data.length; i++)
				{
					if(this.appData.get('user_id') == data[i].username)
					{
						found = true;
						Config.portfolio_access = data[i].permission;
						break;
					}
				}
				if(found == false)
				{
					Config.portfolio_access = 'can_edit';
				}
			},
			error =>
			{
				Config.app_loader = false;
			}
		)
	};

	moveToPortfolio(portfolio_name: string, portfolio_id: number, portfolio: any, state_name?: any, block?: boolean)
	{
		if(this.appData.get('user_type') == 'arc_japan')
		{
			this.appData.set('currency', '¥');
			Config.currency = '$';
			Config.price["arc_pro_payment_type"] = 'all';
		}
		else
		{
			this.appData.set('currency', '$');
			Config.currency = '¥';
			Config.price["arc_pro_payment_type"] = 'yearly';
		}

		this.fetchPrice();
		if(block)
			return
		this.appData.set('portfolio_id', portfolio_id);
		this.appData.set('portfolio_name', portfolio_name);
		this.appData.set('portfolio_source', portfolio.source);
		this.appData.set('portfolio', portfolio);
		this.appData.set('portfolioName', portfolio_name);
		this.appData.set('portfolio_goals', {});
		this.appData.set('portfolio_analysis', {});
		this.getPortfolioTeam();
		
		if(state_name == undefined)
		{
			state_name = 'portfolio-overview';
		}

		this.goToApp("selected_portfolio", 
		{
			"portfolio_id": portfolio_id,
			"app_name": state_name
		});
	}

	addProject(project_type: string)
	{
		this.goToApp("forms", 
		{
			"app_name": "project_creation",
			"project_type": project_type
		});
	}

	claimSchool()
	{
		this.goToApp('claim-school');
	}

	getServiceAgreementUrl()
	{
		var registration_pdf_url = Config.config.settings.registration_pdf;
		
		if(this.appData.get('project_type') == null || this.appData.get('project_type') == undefined)
		{
			return registration_pdf_url;
		}
		else if(this.appData.get('project_type').toLowerCase() == "parksmart")
		{
			registration_pdf_url = Config.config.settings.parksmart_registration_pdf;
		}
		else if(this.appData.get('project_type').toLowerCase() == "transit")
		{
			registration_pdf_url = Config.config.settings.addendum_pdf;
		}

		return registration_pdf_url;
	}

	learnMoreCertText()
	{
		return this.getURLFromParam('leed_id') != undefined ? 'Get Started' : 'Select a project to get started';
	};

	learnMoreCertAction()
	{
		if(this.getURLFromParam('leed_id') != undefined)
		{
			this.upgrade("add_certificate", this.getURLFromParam('leed_id'));
		}
		else
		{
			this.goToApp('projects');
		}
	};

	learnMore(mode: string, leed_id?: number)
	{
		if(leed_id == undefined && Config.project_apps.indexOf(this.appData.get('current_app')) != -1)
		{
			leed_id = this.appData.get('leed_id');
		}
		this.goToApp('learn-more',
			{'mode': mode, 'leed_id': leed_id},
			{'mode': mode, 'leed_id': leed_id}
		);
	}

	showHelpText(type: string)
	{
		if (type == 'total_leed_arc')
		{
			if (parseFloat(this.appData.get('buildingData').score_code_version.name) >= 3)
			{
				type = 'total_leed';
			}
			else
			{
				type = 'total_arc';
			}
		}

		let modalRef
		if(type == 'total_leed')
		{
			modalRef = this.modalService.open(SideBar.totalscore_leed, {windowClass: 'sideBar--projectsDetailsModal'});
		}
		else if(type == 'total_arc')
		{
			modalRef = this.modalService.open(SideBar.totalscore, {windowClass: 'sideBar--projectsDetailsModal'});
		}
		else if(type == 'total_leed_arc_city')
		{
			modalRef = this.modalService.open(SideBar.totalscore_city, {windowClass: 'sideBar--projectsDetailsModal'});
		}
		else if(type == 'team_roles_help')
		{
			modalRef = this.modalService.open(SideBar.team_roles_help, {windowClass: 'sideBar--projectsDetailsModal'});
		}
		else if(type == 'na_help')
		{
			modalRef = this.modalService.open(SideBar.na_help, {windowClass: 'sideBar--projectsDetailsModal'});
		}
		else if(type == 'parksmart')
		{
			modalRef = this.modalService.open(SideBar.parksmart, {windowClass: 'sideBar--projectsDetailsModal'});
		}
	}

	getArabicNumber(number: number | string, survey_code: string)
	{
		if (survey_code != 'ar'){
			return number;
		}
		var id: any = ['٠','١','٢','٣','٤','٥','٦','٧','٨','٩'];
		var rt = ""
		number = String(number)
		for (var i = 0; i < number.length; i++) {
			rt += id[number[i]];
		}
		return rt;
	};

	redirect_to_gsa()
	{
		if ((this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school') && Config.config.settings.space_type_gsa.indexOf(this.appData.get('buildingData').spaceType) > -1){
			this.goToApp("selected_project", 
			{
				"leed_id": this.appData.get('leed_id'),
				"app_name": 'play-to-zero'
			});
		}
	}

	createPortfolio()
	{
		this.appData.set("selected_projects_session", [this.appData.get('leed_id')]);
		this.goToApp('portfolio-creation');
	}

	openPolicy(type?: string)
	{
		let modalRef = this.modalService.open(SideBar.arc_pro_policy, {windowClass: 'sideBar--projectsDetailsModal'}); 
		modalRef.componentInstance.type = type;
	};

	changeDateFormat(date: any, format: string)
	{
		if(date instanceof moment)
		{
			return (<any>date).format(format);
		}
		else
		{
			return moment(date).format(format);
		}
	}

	selectMonth(action: any, date?: any)
    {
        if(!moment(date).isValid())
        {
            date = moment(new Date()).format('YYYY-MM-DD');
        }
        $('.range_input').removeClass('not-active');
        if(date != undefined)
        {
            Config.range_month = moment(date).startOf('month');
        }
        var range_month = Config.range_month;
        if(action == 'subtract')
        {
            range_month = moment(Config.range_month).clone().subtract(1, 'month').startOf('month');
        }
        else if(action == 'add')
        {
            range_month = moment(Config.range_month).clone().add(1, 'month').startOf('month');
            if(range_month.isAfter(Config.max_range.format('YYYY-MM-DD')))
            {
                $('.cursor_next').addClass('not-active');
                return;
            }
        }
        Config.range_month = range_month;
        var days_in_month = Config.range_month.daysInMonth();
        var calendar_month = [];
        for(var i = 0; i < days_in_month; i++)
        {
            var date = Config.range_month.clone().add(i, 'day');
            var weekday = date.weekday(), n = 0;
            while(i == 0 && n < weekday)
            {
                calendar_month.push(date.clone().subtract(weekday - n, 'day'));
                n += 1;
            }
            calendar_month.push(date);
        }
        var last_day = calendar_month[calendar_month.length - 1].day(), n = 6, calendar_month_end = [];
        while(last_day < n)
        {
            calendar_month_end.push(calendar_month[calendar_month.length - 1].clone().add(n - last_day, 'day'));
            last_day += 1;
        }
        calendar_month = calendar_month.concat(calendar_month_end.reverse());
        var calendar_matrix = [], row = [];;
        for(var i = 0; i < calendar_month.length; i++)
        {
            row.push(calendar_month[i]);
            if(row.length == 7)
            {
                calendar_matrix.push(row);
                row = [];
            }
        }
        Config.calendar_matrix = calendar_matrix;
    };

    initRanges(end: any)
	{
		if(!moment(end).isValid())
		{
			end = new Date();
		}
		var start = moment(end).subtract(1, 'year').add(1, 'day').format('YYYY-MM-DD');
		if(window.location.href.indexOf("portfolio") == -1)
		{
			Config.range_start = moment(start);
			Config.range_end = moment(end);
			try
			{
				Config.show_last_certified = false;
				if(this.appData.get('buildingData').certifications.length > 0)
				{
					Config.show_last_certified = true;
					Config.last_certified_date = moment(this.appData.get('buildingData').certifications[this.appData.get('buildingData').certifications.length - 1].date_certified).format('YYYY-MM-DD');
				}
			}
			catch(e){};

			if(end == null || moment(moment(end).format('YYYY-MM-DD')).isSame(moment(new Date()).format('YYYY-MM-DD')))
			{
				this.appData.set('selected_range', 'Last 12 months');
				return;
			}
		}
		else
		{
			end = moment(end).endOf('month');
			Config.range_end = end;
			Config.range_start = moment(end).subtract(1, 'year').add(1, 'day');
			if(end == null || moment(moment(end).format('YYYY-MM-DD')).isSame(moment(new Date()).format('YYYY-MM-DD')))
			{
				this.appData.set('selected_range', 'Last 12 months');
				return;
			}
		}

		if(Config.range_end.format('YYYY-MM-DD') == moment(new Date()).subtract(1, 'year').month('Dec').date(31).format('YYYY-MM-DD'))
		{
			this.appData.set('selected_range', 'End of calendar');
		}
		else if(Config.range_end.format('YYYY-MM-DD') == Config.last_certified_date)
		{
			this.appData.set('selected_range', 'Last certification date');
		}
		else
		{
			this.appData.set('selected_range', 'Custom date range...');
		}
	}

	getSavedRanges()
	{
		let end, start;
		if(this.appData.get('is_admin') || Config.EUserRole == 'GBCI Reviewer')
		{
			end = this.changeDateFormat(Config.range_end, 'YYYY-MM-DD');
		}
		else if(this.appData.get('selected_range') == 'Last 12 months')
		{
			if(window.location.href.indexOf("portfolio") == -1)
			{
				end = moment(new Date()).format('YYYY-MM-DD');
			}
			else
			{
				end = moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
			}
		}
		else if(this.appData.get('selected_range') == 'Last certification date')
		{
			end = Config.last_certified_date;
			end = moment(end).format('YYYY-MM-DD');
		}
		else if(this.appData.get('selected_range') == 'End of calendar')
		{
			end = moment(new Date()).subtract(1, 'year').month('Dec').date(31).format('YYYY-MM-DD');
		}
		else
		{
			if(window.location.href.indexOf("portfolio") == -1)
			{
				end = moment(this.appData.get('buildingData').performance_end_date).format('YYYY-MM-DD');
				if(!moment(end).isValid())
				{
					end = moment(new Date()).format('YYYY-MM-DD');
				}
			}
			else
			{
				end = moment(this.appData.get('portfolio').performance_end_date).format('YYYY-MM-DD');
				if(!moment(end).isValid())
				{
					end = moment(new Date()).endOf('month').format('YYYY-MM-DD'); 
				}
			}
		}
		start = moment(end).subtract(1, 'year').add(1, 'day').format('YYYY-MM-DD');
		return ["&START="+ start + "&END=" + end, start, end];
	}

	selectYear(action: string | undefined, date: any)
	{
		if(!moment(date).isValid())
		{
			date = moment(new Date()).startOf('year').format('YYYY-MM-DD');
		}
		else
		{
			date = moment(date).startOf('year').format('YYYY-MM-DD');
		}
		$('.range_input').removeClass('not-active');
		if(date != undefined)
		{
			Config.range_month = moment(date).startOf('month');
		}
		var range_month = Config.range_month;
		if(action == 'subtract')
		{
			range_month = moment(Config.range_month).clone().subtract(1, 'year').startOf('month');
		}
		else if(action == 'add')
		{
			range_month = moment(Config.range_month).clone().add(1, 'year').startOf('month');
			if(range_month.isAfter(Config.max_range.format('YYYY-MM-DD')))
			{
				$('.cursor_next').addClass('not-active');
				return;
			}
		}
		Config.range_month = range_month;
		var days_in_month = 12;
		var calendar_month = [];
		for(var i = 0; i < days_in_month; i++)
		{
			var date = Config.range_month.clone().add(i, 'month').endOf('month');
			calendar_month.push(date);
		}
		var calendar_matrix = [], row = [];;
		for(var i = 0; i < calendar_month.length; i++)
		{
			row.push(calendar_month[i]);
			if(row.length == 3)
			{
				calendar_matrix.push(row);
				row = [];
			}
		}
		Config.calendar_matrix = calendar_matrix;
	};

	showMaintenanceModal()
	{
		(<any>$('.maintenance_modal')).modal('show');
	}

	fullAccessModal()
	{
		(<any>$('#full_access_modal')).modal('show');
	}

	trialAccessModal()
	{
		(<any>$('#trial_expire_modal')).modal('show');
	}

	filterTrial(app: string, data?: any)
	{
		if((app != 'app.project' && app != 'app.scoreversion' && app != 'app.totalscore' && app != 'app.basescore' && app != 'app.energyscore' && app != 'app.waterscore' && app != 'app.wastescore' && app != 'app.transportationscore' && app != 'app.humanexperiencescore') && this.appData.get('trial_version') && this.appData.get('buildingData').activation_source != 'GRESB')
		{
			this.fullAccessModal();
			return;
		}
		else
		{
			if(Config.maintenance_banner && (Config.maintenance_apps.indexOf(app) != -1))
			{
				this.showMaintenanceModal();
				return;
			}

			this.goToApp("selected_project", 
			{
				"leed_id": this.appData.get('leed_id'),
				"app_name": app
			}, 
			data);
		}
	}

	goBack(mode?: string)
	{
		// if(mode == 'project_creation')
		// {
		// 	this.goToApp('projects');
		// }
		// else if(mode == 'portfolio-overview')
		// {
		// 	this.goToApp("selected_portfolio", 
		// 	{
		// 		"portfolio_id": this.appData.get('portfolio_id'),
		// 		"app_name": "portfolio-overview"
		// 	});
		// }
		if(this.appData.get('app_name') == 'project-setup' || this.appData.get('app_name') == 'project-payment')
		{
			this.goToApp('projects');
			return;
		}
		else if(this.appData.get('previous_app_name') == 'login')
		{
			this.goToApp('home');
			return;
		}
		this.goToApp(this.appData.get('previous_app_name'))
	}

	getUnit(unit: string, project_type: string): any
	{
		if(unit == 'IP')
		{
			if(project_type == 'building' || project_type == 'transit' || project_type == 'school')
			{
				return 'square feet';
			}
			else if(project_type == 'city' || project_type == 'community')
			{
				return 'square miles';
			}
		}
		else if(unit == 'SI')
		{
			if(project_type == 'building' || project_type == 'transit' || project_type == 'school')
			{
				return 'square meters';
			}
			else if(project_type == 'city' || project_type == 'community')
			{
				return 'square kilometers';
			}
		}
	};

	getMax(unit: string, project_type: string): any
	{
		if(unit == 'IP')
		{
			if(project_type == 'building' || project_type == 'transit' || project_type == 'school' || project_type == 'parksmart')
			{
				return '20499999';
			}
			else if(project_type == 'city' || project_type == 'community')
			{
				return '19305';
			}
		}
		else if(unit == 'SI')
		{
			if(project_type == 'building' || project_type == 'transit' || project_type == 'school' || project_type == 'parksmart')
			{
				return '1904514';
			}
			else if(project_type == 'city' || project_type == 'community')
			{
				return '50000';
			}
		}
	};

	isNA(number: any)
	{
		return (number == null || number == undefined || isNaN(parseInt(number))) ? true : false;
	}

	formatNumberCommas(num: any, dec?: any, showNA?: boolean)
	{
		if(dec == undefined)
		{
			dec = 2;
		}
		if((num == null || num == undefined) && showNA)
		{
			return 'NA'
		}

		if(isNaN(parseInt(num)))
		{
			num = '0';
		}

		var threshold: any = "0.", n = 0;
		while(num > 0 && n < dec - 1)
		{
			threshold += "0"
			n += 1
		}
		threshold = parseFloat(threshold + "1");
		if(num > 0 && num < threshold)
		{
			return num.toExponential(dec);
		}
		
		var zeroes = ".";
		for(var i = 0; i < dec; i++)
		{
			zeroes += "0";
		}
		num = parseFloat(num).toFixed(dec).replace(zeroes, "");
		var x = num.split('.');
		var x1 = x[0];
		var x2 = x.length > 1 ? '.' + x[1] : '';
		var rgx = /(\d+)(\d{3})/;
		while (rgx.test(x1))
		{
			x1 = x1.replace(rgx, '$1' + ',' + '$2');
		}
		return x1 + x2;
	}

	upgradeUser(mode: string)
	{
		this.goToApp("upgrade_arc", 
		undefined, 
		{
			"mode": mode
		});
	}

	upgrade(mode: string, leed_id: any, subscriptions?: any)
	{
		this.goToApp("upgrade_arc", 
		{
			"app_name": "upgrade_arc",
			"subscriptions": subscriptions
		}, 
		{
			"mode": mode,
			"leed_id": leed_id,
		});
	}

	getURLFromParam(sParam: string, url?: string): any
	{
		if(url == undefined)
		{
			var sPageURL = window.location.search.substring(1);
            var sURLVariables = sPageURL.split('&');
            for (var i = 0; i < sURLVariables.length; i++)
            {
                var sParameterName = sURLVariables[i].split('=');
                if(sParameterName[0] == sParam)
                {
                	return decodeURI(sParameterName[1]);
                }
            }
		}
		else
		{
			let params: any = new Proxy(new URLSearchParams(window.location.search),
			{
				get: (searchParams, prop) => searchParams.get(<any>prop),
			});
			return params[sParam];
		}
	}

	getBrowser() 
	{
		let ua = navigator.userAgent.toLowerCase();
		let ie = ua.indexOf("msie ") > -1 || ua.indexOf("trident/") > -1 || ua.indexOf("edge") > -1;
		let chrome = false;
		let safari = false;
		
		if (ua.indexOf('safari') != -1)
		{ 
			if (ua.indexOf('chrome') > -1) 
			{
				chrome = true;
			} 
			else 
			{
			safari = true;
			}
		}
		
		return {ie: ie, chrome: chrome, safari: safari}; 
	}

	initNameWatcher()
	{
		var url = '/portfolios/auth0/notifications/';

		this.api.get(url).subscribe(
			response =>
			{
				response = response;
				var client = stream.connect(response.stream_key, null, response.app_id);
				var notification = client.feed(response.feed_name, response.feed_user, response.token);
				
				var subscription = notification.subscribe((data: any) =>
				{
					console.log(data);
				});
			},
			function(error)
			{

			}
		);
	}

	login_complete(): void
	{
		this.appData.set('login', true);
		this.initUserPreference();
		this.initBanners();
		this.initNameWatcher();
		
		if(this.appData.get('app_type') == 'admin' && this.appData.getLocal('auth0') != undefined)
		{
			if(this.appData.getLocal('auth0').is_admin)
			{
				this.goToApp("selected-admin-app", 
				{
					"app_name": "admin_team"
				});
				Config.show_navbar = true;
			}
			else
			{
				this.alertService.alert('error', 'USGBC/GBCI admin can access admin tool only.');
			}
			return;
		}
		else if(this.appData.get('app_type') == 'arc_japan_invoice' && this.appData.getLocal('auth0') != undefined)
		{
			if(this.appData.getLocal('auth0').arcjp_admin)
			{
				this.goToApp("selected-admin-app", 
				{
					"app_name": 'arc_japan_invoice'
				});
				Config.show_navbar = true;
			}
			else
			{
				this.alertService.alert('error', "ArcJP admin's can access this tool only.");
			}
			return;
		}
		else if(this.appData.get('app_type') == 'cagbc_invoice' && this.appData.getLocal('auth0') != undefined)
		{
			if(this.appData.getLocal('auth0').cagbc_review_admin)
			{
				this.goToApp("selected-admin-app", 
				{
					"app_name": 'cagbc_invoice',
					"leed_id": this.getURLFromParam('leed_id', window.location.href)
				});
				Config.show_navbar = true;
			}
			else
			{
				this.alertService.alert('error', "CAGBC admin can access this tool only.");
			}
			return;
		}
		Config.show_navbar = true;
		let next: any = this.getParameterFromURLByName('next', window.location.href);
		if(next == undefined)
		{
			if(window.location.pathname == '/login_middleware' || window.location.pathname == '/')
			{
				this.goToApp("home");
			}
			return;
		}
		let url = window.location.href;
		let subs_key = this.getURLFromParam('subscription-key', url);
		let leed_id = this.getURLFromParam('leed_id', url);
		try{
			if(leed_id == null)
			{
				leed_id = next.split("project/")[1].split("/")[0];
			}
		}
		catch(e){}
		let authorization_token = this.getURLFromParam('authorization_token', url);
		var invoice_number = "", state_name: any;
		var portfolio_id: any = undefined;
		for(var a = 1; a < next.length; a++)
		{
			if(isNaN(next[a]) == false && next[a] != '')
			{
				if (next[a-1] == "project"){
					next[a] = ':project_id';
				}
				else if(next[a-1] == "portfolio"){
					portfolio_id = parseInt(next[a]);
					next[a] = ':portfolio_id';
				}
				else if (next[a-1] == "paynow"){
					invoice_number = next[a];
					next[a] = ':invoice_number';
				}
			}
		}

		if(next.indexOf('learn-more') != -1)
		{
			this.appData.set('back_middleware', true);
			this.goToApp('learn-more', 
			{
				mode: "arc_pro"
			});
		}
		else if(next.indexOf('app/ecostruxure') != -1)
		{
			this.goToApp("ecostruxure"); 
		}
		else if(next == 'ecostruxure-select-project')
		{
			this.goToApp("ecostruxure-select-project");
		}
		else if(next.indexOf('payment-success') != -1)
		{
			let session_id = this.getURLFromParam('session_id', url);
			let payment_type = this.getURLFromParam('payment_type', url);

			this.goToApp(next, 
			{
				"app_name": next,
				"subs_key": subs_key,
				"token": authorization_token,
				"session_id": session_id,
				"payment_type": payment_type,
				"leed_id": leed_id
			}, 
			{
				"leed_id": leed_id,
				"session_id": session_id,
				"payment_type": payment_type
			});
		}
		else if(next.indexOf('payment-cancel') != -1)
		{
			let session_id = this.getURLFromParam('session_id', url);
			let payment_type = this.getURLFromParam('payment_type', url);
			
			if(payment_type == 'arc_pro')
			{
				if(this.appData.get('user_role') == 'arc_pro')
				{
					this.api.get('/auth/user/subscriptions/').subscribe(
						data =>
						{
							var subscriptions = data;
							if(subscriptions.subscribers == undefined)
							{
								subscriptions = {
									"subscribers": []
								}
							}
							this.upgrade("add_subscription", leed_id, subscriptions);
						}
					);
				}
				else
				{
					this.upgrade("purchase_arc_pro", leed_id);
				}
			}
			else if(payment_type == 'climate_risk_report')
			{
				this.searchMoveToProject({
					"leed_id": leed_id
				}, null, 'climate-risk');
			}
			else
			{
				this.searchMoveToProject({
					"leed_id": leed_id
				}, null, 'performance-certificates');
			}
		}
		else if(next.indexOf('portfolio') == -1)
		{
			if (next.indexOf('invoice_number') > -1){
				this.searchMoveToProject({
					"leed_id": leed_id
				}, null, undefined, undefined, {"project_id": leed_id, "invoice_number": invoice_number});
			}
			else
			{
				next = next.replace("manage/", "");
				next = next.replace("/project/" + leed_id, "");
				if(this.appData.get('LO_referer'))
				{
					this.appData.set('LO_referer_id', leed_id);
				}
				if(next.indexOf('data-input') > -1)
				{
					next = "data-input";
				}
				else if(next.indexOf('actions/form') > -1)
				{
					next = "actions";
				}
				this.searchMoveToProject({
					"leed_id": leed_id
				}, null, next);
			}
		}
		else if(portfolio_id != undefined)
		{
			var get_portfolio_project_url = Config.basic_api_url + '/portfolios/ID:' + portfolio_id;
			this.api.get(get_portfolio_project_url).subscribe(
				//success handler
				data =>
				{
					this.searchMoveToPortfolio(portfolio_id, state_name);
				},
				//error handler
				data =>
				{
					this.searchMoveToPortfolio(portfolio_id, state_name);
				}
			);
		}
		else
		{
			if(next != undefined)
			{
				if(Config.project_apps.indexOf(next) != -1)
				{
					this.searchMoveToProject({
						"leed_id": this.getURLFromParam('leed_id')
					}, null, next);
				}
				else if(Config.portfolio_apps.indexOf(next) != -1)
				{
					this.searchMoveToPortfolio(this.getURLFromParam('portfolio_id'), next);
				}
			}
			else
			{
				this.goToApp('home');
			}
		}
	}

	SignAgreementAndGetLOToken()
	{
		Config.app_loader = true;
		this.api.post(
			'/auth/user/sign-agreement/',
			{}
		).subscribe(
			data =>
			{
				Config.app_loader = false;
				if(window.location.href.indexOf('login_middleware') == -1)
				{
					$('#end-user-agreement').modal('hide');
					this.appData.set('LO_key', data.key);
					this.login_complete();
				}
				else
				{
					window.location.reload();
				}
			},
			error =>
			{
				Config.app_loader = false;
			}
		);
	}

	initLoginMiddleware()
	{
		Config.app_loader = false;
		var event = {	
			"url": window.location.origin + "/login_middleware?authorization_token=" + this.appData.getLocal('auth0').authorization_token + "&project-type=all"
		}
		var token = this.appData.getLocal('auth0').authorization_token;

		let authorization_token = token;
		let project_type = this.getURLFromParam('project-type') == null ? 'all' : this.getURLFromParam('project-type');
		let username = this.getURLFromParam('username', event.url);
		let guid     = this.getURLFromParam('guid', event.url);
		let firstname = this.getURLFromParam('firstname', event.url);
		let lastname = this.getURLFromParam('lastname', event.url);
		let session_id = this.getURLFromParam('session_id', event.url);
		let referer = this.getURLFromParam('referer', event.url);
		let help = this.getURLFromParam('help', event.url);

		this.appData.set('closed_score_version_notification', []);
		this.appData.set('closed_message_banner', false);
		this.appData.set('session_id', session_id);
		this.appData.set('referer', referer);

		this.appData.set('token', authorization_token);
		this.api.setAuth(token);

		let headers: any = {
			"Content-Type": "application/json",
			"Ocp-Apim-Subscription-Key": Config.sub_key,
			"Cache-Control": "no-cache",
			"Cache-Pragma": "no-cache",
			"If-Modified-Since": String(new Date().getTime())
		};

		if(this.appData.get("token") != null)
		{
			headers["Authorization"] = "Bearer " + this.appData.get("token");
		}

		this.appData.set('headers', headers);

		if(referer != undefined && referer.toLowerCase().indexOf('leedonline') != -1)
		{
			this.appData.set('LO_referer', true);
		}
		else
		{
			this.appData.set('LO_referer', false);
		}

		if(help != undefined)
		{
			this.appData.set('help', help);
		}
		
		if(username != undefined)
			this.appData.set('user_id', username);
		if(guid != undefined)
			this.appData.set('user_guid', guid);
		if(firstname != undefined)
			this.appData.set('user_firstname', firstname);
		if(lastname != undefined)
			this.appData.set('user_lastname', lastname);
		if(project_type != undefined)
			this.appData.set('project_type', project_type);

		$('#login-modal').modal('hide');
		$('body').removeClass('modal-open');
		$('.modal-backdrop').remove();

		if(window.location.pathname.indexOf('survey') != -1)
		{
			return;
		}
		this.api.get('/auth/usgbc/userprofile/').subscribe(data =>
		{
			this.appData.set("selected_projects_session", []);
			this.appData.set('is_admin', data.is_admin);
			this.appData.set('user_role', data.user_role);
			this.appData.set('profileimg', data.profileimg);
			this.appData.set('user_guid', data.guid);
			this.appData.set('gsa_admin', data.gsa_admin);
			this.appData.set('is_gsa_user', data.is_gsa_user);

			this.appData.set('user_firstname', data.firstname);
			this.appData.set('user_lastname', data.lastname);
			this.appData.set('username', data.username);
			this.appData.set('user_id', data.username);
			
			this.api.get('/auth/access/').subscribe(
				data =>
				{
					if(data.ItRoles[0].AgrName == "Z_LEEDV3_GBCIADMIN" || data.ItRoles[0].AgrName == "Z_LEEDV3_USGBCADMIN" || data.ItRoles[0].AgrName == "Z_LEEDV3_GBCICBADMIN" || data.ItRoles[0].AgrName == "Z_LEEDV3_CBADMIN")
						this.appData.set('is_user_gbci_usgbc_admin', true);
					else
						this.appData.set('is_user_gbci_usgbc_admin', false);
				}
			);

			if (this.appData.get('user_firstname') && this.appData.get('user_firstname') != "")
			{
				if (this.appData.get('user_lastname') && this.appData.get('user_lastname') != "")
				{
					this.appData.set('username', this.appData.get('user_firstname') + " " + this.appData.get('user_lastname'));
				}
				else
				{
					this.appData.set('username', this.appData.get('user_firstname'));
				}
			}
			FS.identify(data.username,
			{
				displayName: data.username
			});
		});

		this.login_complete();
	}

	number_notation(num: number, digits: number)
	{
		if(isNaN(num))
		{
			num = 0;
		}
		var number_set = 
		[
			{ value: 1, symbol: "" },
			{ value: 1E3, symbol: "k" },
			{ value: 1E6, symbol: "M" },
			{ value: 1E9, symbol: "G" },
			{ value: 1E12, symbol: "T" },
			{ value: 1E15, symbol: "P" },
			{ value: 1E18, symbol: "E" }
		];
		var i;
		for (i = number_set.length - 1; i > 0; i--)
		{
			if (num >= number_set[i].value)
			{
				break;
			}
		}
		return (num / number_set[i].value).toFixed(digits) + number_set[i].symbol;
	}

	getFileName(uploaded_file: any)
	{
		try
		{
			return uploaded_file.doc_key.split('/')[ uploaded_file.doc_key.split('/').length - 1 ]
		}
		catch(e)
		{
			return '';
		}
	}

	last12Month()
	{
		var end = moment(new Date());
		var start = end.clone().subtract(1, 'year').add(1, 'day');
		return [start, end];
	};

	downloadXLS(name: string)
	{
		var a = document.createElement('a');
		var linkText = document.createTextNode(name);
		a.appendChild(linkText);
		a.title = name;
		a.href = '../../assets/excel-sheets/' + name;
		document.body.appendChild(a);
		a.click();
	}

	downloadPDF(name: string)
	{
		window.open('../../../../../../assets/pdf/' + name);
	};
        
	openHelpSidebar(page: string)
	{
		let modalRef: any;
		modalRef = this.modalService.open(SideBar.VendorAppHelp, {windowClass: 'sideBar--projectsDetailsModal'});
		modalRef.componentInstance.type = page;
	}   

	triggerReload()
	{
		window.location.reload();
	};

	triggerLogin()
	{
		let app_type: any;
			
		if(window.location.pathname == '/admin')
		{
			app_type = "admin_tool";
		}
		else if(window.location.pathname == '/arc-japan-admin')
		{
			app_type = "arcjp_admin_tool";
		}
		else if(window.location.pathname == '/cagbc-admin')
		{
			app_type = "cagbc_review_admin";
		}

		let deviceId = undefined;
		try
		{
			deviceId = platformApi.deviceId;
		}
		catch(e)
		{
			console.log("Platform API not loaded;");
		}

		let payload: any = {
			"redirect_uri": window.location.origin + "/login_middleware?next=" + window.location.pathname,
			"deviceId": deviceId
		};
		if(app_type != undefined)
		{
			payload["redirect_uri"] += "&app_type=" + app_type;
		}
		if(this.getURLFromParam('user_type') != undefined)
		{
			payload["redirect_uri"] += "&user_type=" + this.getURLFromParam('user_type');
		}
		Config.app_loader = true;
		this.auth0.loginWithRedirect(
		{
			authorizationParams: payload
		});
	}

	sortList(property: any) {
		var sortOrder = 1;
		if(property[0] === "-") {
			sortOrder = -1;
			property = property.substr(1);
		}
		return (a: any, b: any) => {
			var result = ((a[property]).toUpperCase() < (b[property]).toUpperCase()) ? -1 : ((a[property]).toUpperCase() > (b[property]).toUpperCase()) ? 1 : 0;
			return result * sortOrder;
		}
	};

	IS_LO_city_comm()
	{
		if (Config.config.settings.LO_RS_city_comm.indexOf(this.appData.get('buildingData').rating_system) > -1)
		{
			return true;
		}
		else
		{
			return false;
		}
	};

	downloadFile(uploaded_file: any)
	{
		var url    = Config.basic_api_url + "/assets/LEED:" + this.appData.get('leed_id') + "/documents/download/?doc_id=" + uploaded_file["id"] + "&doc_source=S3&access-token=" + this.appData.get('token') + "&subscription-key=" + Config.sub_key;
		var name   = uploaded_file.doc_key.split('/')[ uploaded_file.doc_key.split('/').length - 1 ];
		var a = document.createElement('a');
		var linkText = document.createTextNode(name);
		a.appendChild(linkText);
		a.title = name;
		a.href = url;
		a.target = '_blank';
		document.body.appendChild(a);
		a.style.display = 'none';
		a.click();
	}

	deleteFile(uploaded_file: any, doc_category?: string)
	{
		var putData = 
		{
			doc_id : uploaded_file.id 
		};
		
		Config.app_loader = true;
		this.api.put('/assets/LEED:'+ this.appData.get('leed_id') + '/s3documents/', putData).subscribe(
			//success handler
			data =>
			{
				Config.app_loader = false;
				if(doc_category != undefined)
				{
					this.getUploadedFiles(doc_category);
				}
				else if(this.appData.get('project_type') == 'parksmart')
				{
					this.getUploadedFiles(this.appData.get('measure').measure_id);
				}
				else
				{
					this.getUploadedFiles(this.appData.get('credit').CreditShortId);
				}
			},
			//error handler
			function(response)
			{
				Config.app_loader = false;
			}
		);        
	};

	URLFromRemote(file_url: string, file_name: string, files: any)
	{
		var xhr = new XMLHttpRequest();
		xhr.open("GET", file_url);
		xhr.responseType = "blob";
		xhr.onload = () =>
		{
			var blob = xhr.response;

			var file_save = new File([blob], file_name,
			{
				type: xhr.response.type
			})
			var data = new FormData();
			data.append('action_file', file_save);
							
			if(Config.form_type == 'S3')
			{
				this.uploadToS3(data, Config.upload_category, xhr.response.type, xhr.response.size);
			}
			else
			{
				var data = new FormData();
				data.append('action_file', file_save);
				this.uploadToS3(data, Config.upload_category, file_save.type, file_save.size);
			}
			setTimeout(() =>
			{
				this._ngZone.run(() => { console.log('Outside Done!'); });
			});
		}
		xhr.send();
	};

	openUploadDialog(source: any, upload_category: any, form_type: any)
	{   
		Config.upload_category = upload_category;
		Config.form_type = form_type;
		if(source == 'dropbox')
		{
			Dropbox.appKey = "v6yck5o3m1kqxcq";
			Dropbox.choose(
			{
				success: (files: any) =>
				{
					var blob = this.URLFromRemote(files[0].link, files[0].name, files);
				},

				cancel: function() {},

				linkType: "direct",

				multiselect: false,
	
			});
		}
		if(source == 'google')
		{
			// this.onApiLoad();
		}
		if(source == 'windows')
		{
			OneDrive.open(
				{
					clientId: Config.config.settings.one_drive_key,
					action: "download",
					multiSelect: false,
					openInNewWindow: true,
					advanced:
					{
						redirectUri: window.location.origin + "/app/file-picker",
						loginHint: ""
					},
					success: (files: any) =>
					{
						var blob = this.URLFromRemote(files.value[0]["@microsoft.graph.downloadUrl"], files.value[0]['name'], files.value);
						setTimeout(() =>
						{
							this._ngZone.run(() => { console.log('Outside Done!'); });
						});
					},
					cancel: function() {},
					error: function(e: any) {}
				}
			);
		}
		if(source == 'laptop')
		{
			$('.uploadFile').click();
		}
	};

	fileNameChanged(elem: any)
	{
		var filePath: any = $(elem).val();
		filePath = filePath.replace("/\\/", "\\\\");
		var index = filePath.lastIndexOf("\\");
		filePath = filePath.substring(index + 1, filePath.length);
		//var type = $(this).attr('category');
		var data;
		var temp_uploading = [];
		for(var a = 0; a < $(elem)[0].files.length; a++)
		{
			data = new FormData();
			data.append('action_file', $(elem)[0].files[a]);
			Config.uploading.push($(elem)[0].files[a]);
			this.uploadToS3(data, Config.upload_category, $(elem)[0].files[a].type, $(elem)[0].files[a].size);
		}
		//Config.uploading = temp_uploading;
	};

	uploadToS3(form_data: any, category: string, type: string, size: number)
	{        
		try
		{
			(<any>$('.uploadFile')[0]).value = "";
		}
		catch(e){}
		if(type.split('/')[0] != 'image' && category == 'project-structure-images')
		{
			this.alertService.alert('error', 'Only images are allowed', 5);
			Config.uploading = [];
			return;
		}
		
		if((size / 1024000) > 5.0 && category == 'project-structure-images')
		{
			this.alertService.alert('error', 'File size should not exceed 5MB', 5);
			Config.uploading = [];
			return;
		}
		
		Config.currentUpload = true;
		Config.currentUploadName = "Uploading...";
		var custom_basic_config_header = 
		{
			headers: 
			{
				"responseType": "arraybuffer",
				"Ocp-Apim-Subscription-Key": Config.sub_key,
				"Authorization": "Bearer " + this.appData.get('token')
			}
		};
		form_data.append('upload_category', Config.upload_category)

		this.api.post('/assets/LEED:'+ this.appData.get('leed_id') +'/uploadS3/?upload_category=' + Config.upload_category, form_data, custom_basic_config_header).subscribe(
			//success handler
			response =>
			{
				Config.currentUpload = false;
				Config.currentUploadName = ';';
				this.getUploadedFiles(category);
				this.alertService.alert('success', 'File successfully uploaded.', 5);
			},
			//error handler
			response =>
			{
				Config.currentUpload = false;
				Config.currentUploadName = ';';
				this.getUploadedFiles(category);
				this.alertService.alert('error', 'Error uploading file. Please try again.', 5);
			}
		);
	};

	getUploadedFiles(doc_category: string)
	{
		// Config.app_loader = true;
		this.api.get(
			'/assets/LEED:'+ this.appData.get('leed_id') + '/s3documents/?doc_category=' + doc_category
		).subscribe(data =>
		{
			// Config.app_loader = false;
			
			if(Config.uploading.length > 0)
			{
				for(var a in data)
				{
					for(var b in Config.uploading)
					{
						if(this.getFileName(data[a]) == Config.uploading[b].name)
						{
							Config.uploading.splice(b, 1)
						}
					}
				}    
			}
			
			Config.uploaded_files = data;
			Config.uploaded_files_energy = [];
			Config.uploaded_files_water = [];
			Config.uploaded_files_waste = [];
			Config.uploaded_files_tranport = [];
			Config.uploaded_files_human = [];
			Config.uploaded_files_excel = [];
			Config.uploaded_files_add = [];
			Config.uploaded_files_form = [];
			
			for(var i = 0; i < data.length; i++)
			{
				if(data[i].doc_category == 'PF901')
				{
					Config.uploaded_files_energy.push(data[i]);
				}
				else if(data[i].doc_category == 'PF902')
				{
					Config.uploaded_files_water.push(data[i]);
				}
				else if(data[i].doc_category == 'PF903')
				{
					Config.uploaded_files_waste.push(data[i]);
				}
				else if(data[i].doc_category == 'PF904')
				{
					Config.uploaded_files_tranport.push(data[i]);
				}
				else if(data[i].doc_category == 'PF905')
				{
					Config.uploaded_files_human.push(data[i]);
				}
				else if(data[i].doc_category == 'excel')
				{
					Config.uploaded_files_excel.push(data[i]);
				}
				else if(data[i].doc_category == 'PF906')
				{
					Config.uploaded_files_add.push(data[i]);
				}
				else
				{
					if(data[i].doc_category == 'base_score')
					{
						var base_review_key = "base_score_" + this.appData.get('leed_id') + "_" + this.appData.get('buildingData').key + ".pdf";
						if(data[i].doc_key.indexOf(base_review_key) == -1)
						{
							Config.uploaded_files_form.push(data[i]);
						}
					}
					else
					{
						Config.uploaded_files_form.push(data[i]);
					}
				}
			}
			setTimeout(() =>
			{
				this._ngZone.run(() => { console.log('Outside Done!'); });
			});
		}, 
		error =>
		{
			Config.app_loader = false;
		});
	}

	openEnergyStar()
	{
		$('.energyStarPrompt_modal').modal('toggle');
	}

	noAccessModal()
	{
		$('.no_access_project').modal('toggle');
	}

	check_login_status()
	{
		if(Config.login_app_redirect == "agreements")
		{
			this.acceptAgreement();
		}
		else if(Config.login_app_redirect == "project-payment")
		{
			$('.login_modal').modal('hide');
			this.appData.set('payment_init', this.appData.get('current_app'));
			this.goToApp("project-payment", 
			{
				"leed_id": this.appData.get('leed_id'),
				"app_name": 'project-payment',
				'init': this.appData.get('current_app')
			});
		}
		else if(Config.login_app_redirect == "contact_admin")
		{
			var data = {"user_name": this.appData.get('user_id')};
			Config.login_modal_button_flag = true;
			this.api.post('/assets/LEED:' + this.appData.get('leed_id') + '/permissions/requests/',data).subscribe(
				//success handler
				data =>
				{
					Config.login_modal_button_flag = false;
					$('.login_modal').modal('hide');
					this.goToApp('projects');
				},
				//error handler
				error =>
				{
					Config.login_modal_button_flag = false;
					$('.login_modal').modal('hide');
					this.goToApp('projects');
				}
			);
		}
		else
		{
			$('.login_modal').modal('hide');
			this.goToApp('projects');
		}
	}

	loginRemoveError()
	{
		Config.error_msz    = "";
		Config.error_flag   = false;
		Config.success_msz  = "";
		Config.success_flag = false;
	};

	validateToken()
    {
		if(this.appData.get('app_name') == 'survey' || this.appData.get('app_name') == 're-entry-survey')
		{
			return;
		}
        else if(this.appData.get('app_name') != null && this.appData.get('current_app') != 'login')
        {
			this.goToApp('login', null, {
				'leed_id': this.appData.get('leed_id'),
				'next': this.appData.get('app_name'),
				'portfolio_id': this.appData.get('portfolio_id')
			});
        }
		else if(window.location.pathname.indexOf('login') == -1 && window.location.pathname.indexOf('admin') == -1)
		{
			this.goToApp('login', null, {
				'next': window.location.pathname
			});
		}
    }

	anchorDownload(name: string, url: string)
	{
		let a = document.createElement('a');
		let linkText = document.createTextNode(name);
		a.appendChild(linkText);
		a.title = name;
		a.href = url;
		a.target = '_blank';
		a.style.display = 'none';
		document.body.appendChild(a);
		a.click();
	};

	getGBCIDocsUrl()
	{
		return '../../assets/pdf/GBCI-India.zip';
	}

	unixDateFormat(date: any)
	{
		return this.dateTimeFormat(moment(date, 'YYYYMMDDHHmmss').subtract(new Date().getTimezoneOffset(), 'minutes').format("YYYY-MM-DD HH:mm")); 
	};

	formatDateT(date: any)
	{
		var val = String(date);
		if (val.indexOf("T") > -1)
		{
			return date;
		}
		else
		{
			return date + 'T00:00:00';
		}
	}

	getScoreVersionScenario(rating_system: any)
	{
		// v2.0 -> v3.2 (scenario_1)
		// v2.1 -> v3.3 (scenario_1)
		// v3.0 -> v3.2 (scenario_2)
		// v3.2 -> v3.3 (scenario_3)
		// v2.0 -> v2.1 (scenario_4)

		if ( Config.score_version_name_for_compare == '3.3' && (this.appData.get('buildingData').score_code_version.name == "2.0" || this.appData.get('buildingData').score_code_version.name == "2.1") ){
			return 'scenario_1';
		}
		else if ( Config.score_version_name_for_compare == '3.3' && (this.appData.get('buildingData').score_code_version.name == "3.0" || this.appData.get('buildingData').score_code_version.name == "3.1" || this.appData.get('buildingData').score_code_version.name == "3.2") ){
			return 'scenario_2';
		}
		// else if (this.appData.get('buildingData').score_code_version.name == "3.0" && Config.score_version_name_for_compare == '3.2'){
		// 	return 'scenario_2';
		// }
		// else if (this.appData.get('buildingData').score_code_version.name == "3.2" && Config.score_version_name_for_compare == '3.3'){
		// 	return 'scenario_3';
		// }
		// else if (this.appData.get('buildingData').score_code_version.name == "2.0" && Config.score_version_name_for_compare == '2.1'){
		// 	return 'scenario_4';
		// }

		return null;
	}

	getVersionComparisonScores(new_version_name: any)
	{
		Config.score_comparison_received = false;
		this.ScoreCodeVersion.compare(this.appData.get('buildingData').score_code_version.name + '-' + new_version_name).subscribe(
			//success handler
			data => {
				Config.score_comparison_received = true;
				for (var i = 0; i < data.length; i++) {
					if (data[i].code_version == this.appData.get('buildingData').score_code_version.name){
						Config.old_version_scores = data[i];
					}
					else if (data[i].code_version == new_version_name) {
						Config.new_version_scores = data[i];
					}
				}
			}, 
			//error handler
			data =>{
				Config.score_comparison_received = true;
				if (data.message != undefined && data.message.indexOf("missing for this project") > -1){
					this.alertService.alert('error', data.message.split("'")[1], 5);
				}
				else{
					this.alertService.alert('error', 'Something went wrong while comparing scores. Please try again.', 5);
				}
			}
		);
	}

	refreshExtensionCardInfoHeight()
	{
		$('#extension_card_info_1').removeAttr("style");
		$('#extension_card_info_2').removeAttr("style");
		var div_new: any = $('#extension_card_info_1').height();
		var div_old: any = $('#extension_card_info_2').height();
		if (div_new > div_old){
			$('#extension_card_info_2').height((<any>$('#extension_card_info_1')).height()+10);
		}
		else if (div_old > div_new){
			$('#extension_card_info_1').height((<any>$('#extension_card_info_2')).height()+10);
		}
	}

	goToScoreComparison(new_version_name: string, new_version_id: string)
	{
		Config.score_version_name_for_compare = new_version_name;
		Config.score_version_id_for_compare = new_version_id;
		this.refreshExistingExtensionRequest();
		try
		{
			Config.score_scenario = this.getScoreVersionScenario(this.appData.get('buildingData').score_code_version.rule_set_rating_system);
		}
		catch(e)
		{
			Config.score_scenario = null;
		}
		$('#score_code_version_review_block').modal("hide");
		$('#score_code_version_notification').modal("hide");
		Config.arc_hide_all_content = true;
		$('#arc_copyright_footer').hide();
		Config.version_comparison_show_modal = true;
		this.getVersionComparisonScores(new_version_name);
		setTimeout(() => {  this.refreshExtensionCardInfoHeight(); }, 100);

		this.api.getAsset(
			'../../assets/templates/score/'  + Config.score_scenario + '_v' + this.appData.get('buildingData').score_code_version.name + '.html',
			'text/html'
		).subscribe(
			data =>
			{
				Config.score_version_help_left = data;
			}
		);
		this.api.getAsset(
			'../../assets/templates/score/' + Config.score_scenario + '_v' + Config.score_version_name_for_compare + '.html',
			'text/html'
		).subscribe(
			data =>
			{
				Config.score_version_help_right = data;
			}
		);
	}

	scoreVrsionMailto()
	{
		if (this.appData.get('buildingData').project_type == 'city' || this.appData.get('buildingData').project_type == 'community')
		{
			window.location.href = "mailto:contact@arcskoru.com?subject=" + this.appData.get('project_id') + " - Arc " + this.appData.get('buildingData').project_type + " score v2.0";
		}
		else
		{
			window.location.href = "mailto:contact@arcskoru.com?subject=" + this.appData.get('project_id') + " - Arc score v" + Config.new_score_version_detail.name;
		}
	};

	dayDiff(b: any, a: any): any
	{
		var _MS_PER_DAY = 1000 * 60 * 60 * 24;
		var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
		var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
		return Math.floor((utc2 - utc1) / _MS_PER_DAY);
	}

	newDateT(date: any): any
	{
		var day   = ('0' + String(date.getDate())).slice(-2);
		var month = ('0' + String(date.getMonth()+1)).slice(-2);
		var year  = String(date.getFullYear());
		return new Date(year+'-'+month+'-'+day+"T00:00:00")
	}

	notNowDropdown()
	{
		var final_date          = new Date(this.appData.get('buildingData').score_code_version.final_date_to_update+"T00:00:00");
		if (Config.extension_request_exist.is_granted){
			final_date          = new Date(Config.extension_request_exist.grant_till+"T00:00:00");
		}
		var next_month          = (this.newDateT(new Date())).setMonth((this.newDateT(new Date())).getMonth()+1);
		var next_month_date_obj = new Date(next_month)
		if (this.dayDiff(final_date, next_month_date_obj) < 1){
			delete Config.not_now_dropdown["next_month"];
			if (this.dayDiff(final_date, this.newDateT(new Date())) < 7){
				delete Config.not_now_dropdown["next_week"];
				if (this.dayDiff(final_date, this.newDateT(new Date())) < 1){
					delete Config.not_now_dropdown["tomorrow"];
					Config.notification_only_close = true;
				}
			}
		}
	}

	showNotificationModal(forceCall: boolean)
	{
		forceCall = typeof forceCall !== 'undefined' ? forceCall : false;
		Config.app_loader = true;
		this.ScoreCodeVersion.get().subscribe(
			//success handler
			data => {
				Config.app_loader = false;
				var current_version = parseFloat(this.appData.get('buildingData').score_code_version.name);
				for (var i = 0; i < data.length; i++) {
					if (parseFloat(data[i].name) > current_version) {
						Config.new_score_version_detail = data[i];
						Config.new_version_change_log = data[i].changes_log != "" ? data[i].changes_log.split(";") : [];
					}
				}
				this.notNowDropdown();
				if (!forceCall && !this.appData.get('buildingData').score_version_update_pending){
					$('#score_code_version_notification').modal("show");
				}
			},
			error =>
			{
				Config.app_loader = false;
			}
		);
	}

	refreshExistingExtensionRequest()
	{
		Config.extension_request_exist = {};
		for (var i = 0; i < Config.score_version_extension_request.length; i++) {
			if ((Config.score_version_extension_request[i].current_version.name == this.appData.get('buildingData').score_code_version.name) && (Config.score_version_extension_request[i].is_granted)){
				Config.extension_request_exist = Config.score_version_extension_request[i];
				break;
			}
		}
	}

	final_date_to_update_string()
	{
		this.refreshExistingExtensionRequest();
		if (Config.extension_request_exist.is_granted){
			Config.final_date_to_update_string_val = this.dateFormat(Config.extension_request_exist.grant_till) + ' (this project received an extension)';
		}
		else{
			Config.final_date_to_update_string_val = this.dateFormat(this.appData.get('buildingData').score_code_version.final_date_to_update);
		}
	}

	initScoreCodeVersion(forceCall: boolean)
	{
		if (this.appData.get('buildingData').project_type == 'parksmart' || this.appData.get('buildingData').project_type == 'sites' || this.appData.get('buildingData').score_code_version.latest){
			Config.app_loader = false;
			return;
		}
		forceCall = typeof forceCall !== 'undefined' ? forceCall : false;
		// Score version update variable
		Config.extension_request_text          = "Request Extension";
		Config.extension_request_flag          = false;
		Config.score_code_other_upgrade        = {text: ""};
		Config.scoreNotificationCheckStatus    = {accept: false};
		Config.current_score_version_detail    = {};
		Config.new_score_version_detail        = {};
		Config.new_version_change_log          = [];
		Config.current_version_change_log      = [];
		Config.old_version_scores              = {};
		Config.new_version_scores              = {};
		Config.not_now_dropdown                = {"tomorrow":"Remind me tomorrow", "next_week":"Remind me in one week", "next_month":"Remind me in one month"};
		Config.score_version_confirmation_flag = false;
		Config.score_version_confirmation_text = "Update my score";
		Config.score_comparison_received       = false;
		Config.score_version_notification      = [];
		Config.arc_hide_all_content            = false;
		Config.version_comparison_show_modal   = false;
		Config.score_version_extension_request = [];
		Config.notification_only_close         = false;
		Config.final_date_to_update_string_val = "";
		Config.score_version_name_for_compare  = "";
		Config.score_version_id_for_compare    = null;
		// Check for score code version
		if (forceCall || this.appData.get('closed_score_version_notification').indexOf(this.appData.get('buildingData').leed_id) < 0){
			this.ScoreCodeVersion.getNotification().subscribe(
				//success handler
				data => {
					Config.score_version_notification = data[0];
					if (data.length>0) {
						if (new Date(this.formatDateT(data[0].notify_on)) <= new Date()){
							this.showNotificationModal(forceCall);
						}
						else if (forceCall){
							this.showNotificationModal(forceCall);
						}
					}
					else{
						this.showNotificationModal(forceCall);
					}
				}
			);
			this.ScoreCodeVersion.getExtensionRequest().subscribe(
				//success handler
				data => {
					Config.score_version_extension_request = data;
					this.final_date_to_update_string();
				}
			);
		}
		else{
			Config.app_loader = false;
		}
	}

	showUserScoreVersionUpdateNotification()
	{
		this.ScoreCodeVersion.getScoreVersionUpdateUserNotification().subscribe(
			//success handler
			data => {
				if (data.length){
					Config.user_score_version_update_notification = data[0];
					this.ScoreCodeVersion.get().subscribe(
						//success handler
						data => {
							for (var i = 0; i < data.length; i++) {
								if (data[i].name == Config.user_score_version_update_notification.version_log.new_version.name) {
									Config.notification_version_change_log = data[i].changes_log.split(";")
									break;
								}
							}
							$('#score_version_updated_user_notification').modal('show');
						}
					);
				}
			}
		);
	}

	getScoreTotal(score_obj: any){
		if(score_obj == undefined)
			return
		var total = 0;
		var keys  = ['energy', 'water', 'waste', 'transport', 'human_experience', 'base_score'];
		Object.keys(score_obj).forEach(function(key) {
			if (keys.indexOf(key) > -1){
				if (score_obj[key] == null || score_obj[key] == ""){
					total += 0;
				}
				else{
					total += score_obj[key];
				}
			}
		});
		return total;
	};

	toggleScoreHelp()
	{
		Config.show_score_help = !Config.show_score_help;
	};

	openPendingScoreUpdateConfirmationModal()
	{
		$('#pending_score_update_confirmation').modal('show');
	}

	updateScoreVersion()
	{
		Config.score_version_confirmation_flag = true;
		Config.score_version_confirmation_text = "Updating...";
		this.ScoreCodeVersion.updateVersion(Config.score_version_id_for_compare).subscribe(
			//success handler
			data => {
				this.projects.getAssetDetails(this.appData.get('leed_id')).subscribe(
					//success handler
					data => {
						Config.score_version_confirmation_flag = false;
						Config.score_version_confirmation_text = "Update my score";
						this.appData.set('buildingData', data);
						if (this.appData.get('buildingData').score_version_update_pending){
							this.openPendingScoreUpdateConfirmationModal();
						}
						else{
							// $state.reload('app.scoreversion', {"project_id": this.appData.project_id});
							this.closeScoreComparisonWindow();
							this.alertService.alert('success', 'Successfully updated to new version', 5);
						}
					}
				);
			}, 
			//error handler
			data =>{
				Config.score_version_confirmation_flag = false;
				Config.score_version_confirmation_text = "Update my score";
				if (data.non_field_errors == undefined){
					this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
				}
				else{
					var error_text = data.non_field_errors[0];
					this.alertService.alert('error', error_text, 5);
				}
			}
		);
	}

	skip_score_update()
	{
		this.postponeVersionNotification('close');
		this.closeScoreComparisonWindow();
	}

	closeScoreComparisonWindow()
	{
		Config.version_comparison_show_modal = false;
		Config.arc_hide_all_content          = false;
		$('#arc_copyright_footer').show();
	}

	initFdatePicker(element: any)
	{
		var nowTemp       = new Date();
		var now           = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);
		var disable_after = this.extension_till_calendar_date();
		(<any>$('#'+element)).fdatepicker({
			format: 'M dd, yyyy',
			onRender: (date: any) => {
				return date.valueOf() < disable_after.valueOf() ? 'disabled' : '';
			}
		});
	}

	extension_till_calendar_date(): any
	{
		var nowTemp       = new Date();
		var now           = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);
		var final_date = new Date(this.appData.get('buildingData').score_code_version.final_date_to_update+"T00:00:00")
		final_date.setDate(final_date.getDate()+1)
		if (now > final_date){
			return now;
		}
		else{
			return final_date;
		}
	}
	
	postponeVersionNotification(till: string)
	{
		var today     = new Date();
		var notify_on = new Date();
		if (till == "close"){
			$('#score_code_version_notification').modal("hide");
			this.appData.get('closed_score_version_notification').push(this.appData.get('leed_id'));
			return;
		}
		else if (till == "tomorrow"){
			$('#score_code_version_notification').modal("hide");
			notify_on.setDate(today.getDate()+1);
		}
		else if (till == "next_week"){
			$('#score_code_version_notification').modal("hide");
			notify_on.setDate(today.getDate()+7);
		}
		else if (till == "next_month"){
			$('#score_code_version_notification').modal("hide");
			notify_on.setMonth(today.getMonth()+1);
		}
		else if (till == "other_upgrade_option"){
			this.final_date_to_update_string();
			this.initFdatePicker('extension_approval_till');
			var date = new Date();
			Config.extension_approval_till = moment(this.extension_till_calendar_date()).format("MMM DD, YYYY");
			$('#extension_approval_till').val(Config.extension_approval_till);
			Config.score_code_other_upgrade = {text: ""};
			$('#score_code_version_review_block').modal("hide");
			$('#score_code_version_notification').modal("hide");
			$('#skip_score_update').modal("hide");
			$('#score_code_other_upgrade_options').modal("show");
			return;
			
		}
		else if (till == "no_notification"){
			if (Config.scoreNotificationCheckStatus.accept){
				notify_on = new Date('9999-12-31T00:00:00');
			}
			else{
				this.ScoreCodeVersion.deleteNotification().subscribe(
					//success handler
					function(data) {
						
					}
				);
				return;
			}
		}
		var date_payload: any = String(notify_on.getFullYear()) + '-' + ('0' + String(notify_on.getMonth() + 1)).slice(-2) + '-' + ('0' + String(notify_on.getDate())).slice(-2);
		this.ScoreCodeVersion.postNotification(String(date_payload)).subscribe(
			//success handler
			function(data) {
			}
		);
	}

	goToScoreVersion()
	{
		$('#score_code_version_notification').modal("hide");
		$('#score_code_version_review_block').modal("hide");
		this.goToApp("selected_project", 
		{
			"leed_id": this.appData.get('leed_id'),
			"app_name": 'score-version'
		});
	}

	deleteUserScoreVersionUpdateNotification()
	{
		this.ScoreCodeVersion.deleteScoreVersionUpdateUserNotification().subscribe(
			function(data) {}
		);
	}

	closePendingScoreUpdateConfirmationModal()
	{
		this.closeScoreComparisonWindow();
		if (document.URL.indexOf("/manage/scoreversion") < 0){
			this.goToApp("selected_project", 
			{
				"leed_id": this.appData.get('leed_id'),
				"app_name": 'score-version'
			});
		}
		else{
			this.triggerReload();
		}
		$('#pending_score_update_confirmation').modal('hide');
	}

	updateUserPreference(elem: any)
	{
		this.auth.updateUserPreference('score_version_notifictaion', elem.checked).subscribe(
			//success handler
			function(data){
			}
		);
	}

	initUserPreference()
	{
		this.auth.getUserPreference().subscribe(
			//success handler
			(data) =>
			{
				var elem: any = document.querySelector('.new_score_version_notification');
				elem.checked = data[0].is_selected;
				this.appData.set('unit_preference', data[0]);
			}
		);
	}

	scoreVersionExtensionRequest()
	{
		this.postponeVersionNotification('close');
		Config.extension_request_text   = "Requesting...";
		Config.extension_request_flag   = true;
		var need_approval_till = moment($('#extension_approval_till').val()).format("YYYY-MM-DD");;
		this.ScoreCodeVersion.extensionRequest(Config.score_code_other_upgrade.text, Config.new_score_version_detail.id, need_approval_till).subscribe(
			//success handler
			data =>
			{
				Config.extension_request_text = "Request Extension";
				Config.extension_request_flag = false;
				$('#score_code_other_upgrade_options').modal("hide");
				Config.score_version_extension_request.unshift(data);
				this.final_date_to_update_string();
				this.alertService.alert('success', 'Thank you for your request! We will review it and will get back to you.', 10);
				if (document.URL.indexOf("/manage/scoreversion") > -1)
				{
					this.triggerReload();
				}
			}, 
			//error handler
			data =>
			{
				Config.extension_request_text = "Request Extension";
				Config.extension_request_flag = false;
				if (data.data.non_field_errors == undefined)
				{
					this.alertService.alert('error', 'Something went wrong. Please try again.', 5);
				}
				else
				{
					var error_text = data.error.non_field_errors[0];
					this.alertService.alert('error', error_text, 5);
				}
			}
		);
	}

	redirect_to_mypojects_page()
	{
		this.goToApp('projects');
		$('.token_incorrect_modal').modal('hide');
		$('.login_modal').modal('hide');
	}

	searchDisable()
	{
		$('.searchDropdown').hide();
		Config.searchResults_leed_id = [];
		Config.searchResults_pf_id = [];
	}

	installedApps()
	{
		this.appData.set('energyStar_installed', false);
		this.appData.set('dropbox_installed', false);
		this.appData.set('oneDrive_installed', false);
		this.appData.set('googleDrive_installed', false);

		this.api.get(
			'/assets/LEED:' + this.appData.get('leed_id') + '/apps/'
		).subscribe(
			data =>
			{
				for (var i = 0; i < data.length; i++) 
				{
					if (data[i].apps.id == 1)
					{
						this.appData.set('energyStar_installed', true);
					}
					else if (data[i].apps.id == 2)
					{
						this.appData.set('dropbox_installed', true);
					}
					else if (data[i].apps.id == 3)
					{
						this.appData.set('oneDrive_installed', true);
					}
					else if (data[i].apps.id == 4)
					{
						this.appData.set('googleDrive_installed', true);
					}
				}
			}
		)
	}

	downloadFromProxy(url: string)
	{
		var hiddenIFrameID = 'hiddenDownloader',
		iframe: any = document.getElementById(hiddenIFrameID);
		if(iframe === null) {
			iframe = document.createElement('iframe');
			iframe.id = hiddenIFrameID;
			iframe.style.display = 'none';
			
			document.body.appendChild(iframe);
		}
		iframe.src = url;
	}

	getPolicyUrl(creditShortId: any)
	{

		var policyUrlArray: any = []; 

		var host = 'http://www.usgbc.org/sites/default/files/';
	  
			
		policyUrlArray["SS103"] = "SSp%2BSite%2BManagement%2Bpolicy.doc"; //Site Management Policy
		policyUrlArray["MR105"] = "MRp%2BOngoing%2BPurchasing%2Band%2BWaste%2Bpolicy.doc"; //Ongoing Purchasing and Waste Policy
		policyUrlArray["MR106"] = "MRp%2BFacility%2BMaintenance%2Band%2BRenovation%2BPolicy.doc"; //Facility Maintenance and Renovation Policy
		
		policyUrlArray["EQ108"] = "EQp%2BGreen%2BCleaning%2BPolicy%2Btemplate.doc"; //Green Cleaning Policy
		policyUrlArray["WE103"] = "WEp%2BIndoor%2BWater%2BUse%2BReduction%2Bpolicy.doc"; //Indoor Water Use Reduction
		//policyUrlArray[""] = "EQc%2BIntegrated%2BPest%2BManagement%2Bplan.doc"; // Integrated Pest Management plan

		var getPolicyUrl = host + policyUrlArray[creditShortId];

		return getPolicyUrl;
	}

	handleNaN(data: any)
	{
		if(isNaN(data) || data == null || data == "null")
			return "-";
		return this.addCommas(data);
	}

	handleDecimals(data: any)
	{
		if(isNaN(data) || data == null || data == "null")
			return "-";
		return this.addCommas(Math.round(data * 1000) / 1000);
	}

	getProjectScore(project: any): number
	{
		var energy           = ((isNaN(parseInt(project.energy)) ? 0 : parseInt(project.energy)));
		var water            = ((isNaN(parseInt(project.water)) ? 0 : parseInt(project.water)) );
		var waste            = ((isNaN(parseInt(project.waste)) ? 0 : parseInt(project.waste)));
		var human_experience = ((isNaN(parseInt(project.human_experience)) ? 0 : parseInt(project.human_experience)) );
		var transport        = ((isNaN(parseInt(project.transport)) ? 0 : parseInt(project.transport))) ;
		var base_score       = ((isNaN(parseInt(project.base_score)) ? 0 : parseInt(project.base_score)) / 10);
		return (energy + water + waste + human_experience + transport + base_score);
	};

	isEmpty(obj: any)
	{
		return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
	}

	getAgreementHTML(): any
	{
		if(this.isEmpty(this.appData.get('buildingData')))
		{
			return;
		}
		var addendum_pdf_url = '';
		var project_certification = this.appData.get('buildingData').certification;
		
		if (project_certification != undefined && project_certification != null && this.isLEEDCertified(project_certification)){
			addendum_pdf_url = Config.config.settings.addendum_agreement;
		}
		else if (!(this.checkInCurrentRatingSystem() || this.appData.get('buildingData').rating_system.toLowerCase() == 'none' || this.appData.get('buildingData').rating_system.toLowerCase() == 'other')){
			addendum_pdf_url = Config.config.settings.addendum_agreement;
		}
		else{
			if (this.appData.get('buildingData').project_type.toLowerCase() == 'parksmart'){
				addendum_pdf_url = Config.config.settings.parksmart_registration_agreement;
			}
			else if (this.appData.get('buildingData').project_type.toLowerCase() == 'transit'){
				addendum_pdf_url = Config.config.settings.addendum_agreement;
			}
			else{
				addendum_pdf_url = Config.config.settings.registration_agreement;
			}
		}
		return addendum_pdf_url;
	};

	reportFormatNumber(number: any,decimals: number)
	{
		var amt: any = parseFloat(number);
		amt = amt.toFixed(decimals);
		if(isNaN(amt) || amt == Infinity)
		{
			return 0.0000
		}
		else
		{
			return parseFloat(amt)
		}
	};

	isNoneApplicableForRecert(){
		var certifications = this.appData.get('buildingData').certifications;
		if (certifications.length > 0){
			for (var i = certifications.length - 1; i >= 0; i--) {
				if (certifications[i].rating_system != 73 && certifications[i].rating_system != 74){
					return true;
				}
			}
		}
		return false;
	};

	isPreCertified(certification: any){
		if (certification.toLowerCase() == "pre-certified"){
			return true;
		}
		else{
			return false;
		}
	};

	IsScoreVersionUpdated(){
		var today_date = this.newDateT(new Date());
		if (this.appData.get('buildingData').project_type != 'parksmart' && this.appData.get('buildingData').project_type != 'sites' && !this.appData.get('buildingData').score_code_version.latest && this.appData.get('buildingData').score_code_version.sunset_date_notification && today_date > new Date(this.appData.get('buildingData').score_code_version.final_date_to_update+"T00:00:00")){
			if (this.appData.get('buildingData').override_score_code_version){
				if (today_date > new Date(this.appData.get('buildingData').override_score_code_version_till+"T00:00:00")){
					return false;
				}
			}
			else{
				return false;
			}
		}
		return true;
	};

	gotoReview(){
		if(Config.maintenance_banner)
		{
			this.showMaintenanceModal();
			return;
		}
		if (Config.config.settings.RS_V4_1.indexOf(this.appData.get('buildingData').rating_system) > -1 && !this.isLEEDCertified(this.appData.get('buildingData').certification)){
			Config.cert_loader = true;
			this.auth.getLeedOnlineToken().subscribe(
				data => {
					Config.cert_loader = false;
					var redirectPage = encodeURIComponent("projects/timeline/"+this.appData.get('buildingData').leed_id);
					var redirect_url  = Config.config.settings.LO_URL+ "projects/register?cmd=arc2LO&id=" + this.appData.get('buildingData').leed_id;
					window.open(redirect_url,'_blank');
				},
				error => {
					Config.cert_loader = false;
				}
			);
		}
		else if ((this.appData.get('project_rating_system').toLowerCase() == "none" || this.appData.get('project_rating_system').toLowerCase() == "other") && !this.isNoneApplicableForRecert()) {
			if (this.appData.get('project_type') == 'building' || this.appData.get('project_type') == 'school'){
				Config.cert_loader = true;
				this.auth.getLeedOnlineToken().subscribe(
					data => {
						Config.cert_loader = false;
						var redirectPage = encodeURIComponent("projects/register?cmd=arc2LO&id="+this.appData.get('buildingData').leed_id);
						var redirect_url  = Config.config.settings.LO_URL+ "projects/register?cmd=arc2LO&id=" + this.appData.get('buildingData').leed_id;
						window.open(redirect_url,'_self');
					},
					error => {
						Config.cert_loader = false;
					}
				);
			}
			else{
				window.open(Config.LO_URL,'_blank');
			}
		}
		else if (this.IsScoreVersionUpdated() || this.appData.get('buildingData').score_version_update_pending){
			this.goToApp('selected_project', { 'leed_id': this.appData.get('leed_id'), 'app_name': 'review-selection'});
		}
		else{
			this.initScoreCodeVersion(true);
			$('#score_code_version_review_block').modal("show");
		}
	};

	needReviewTab(project: any){
		try
		{
			Config.need_review_tab = true;
			if (project.rating_system != null && project.rating_system != '' && (Config.config.settings.NO_REVIEW_RS.indexOf(project.rating_system) > -1 || project.project_type == 'parksmart'))
			{
				Config.need_review_tab = false;
			}
			else if (!this.checkInCurrentRatingSystem() && !(Config.config.settings.RS_V4_1.indexOf(project.rating_system) > -1))
			{
				if (project.rating_system.toLowerCase() == 'none' || project.rating_system.toLowerCase() == 'other')
				{
					Config.need_review_tab = true;
				}
				else if (!this.isLEEDCertified(this.appData.get('buildingData').certification)){
					Config.need_review_tab = false;
				}
			}
			return Config.need_review_tab;
		}
		catch(e)
		{
			Config.need_review_tab = false;
			return Config.need_review_tab;
		}
	};

	showRatingSystemLabel(rating_system: string)
	{
		if(rating_system == 'LEED V4 BD+C: CT' || rating_system == 'LEED-CT')
		{
			return 'LEED for Cities'
		}
		else if(rating_system == 'LEED V4 BD+C: CM' || rating_system == 'LEED-CM')
		{
			return 'LEED for Communities'
		}
		else if(rating_system == 'LEED V4 BD+C: DT' || rating_system == 'LEED-DT')
		{
			return 'LEED for Districts'
		}
		else if(rating_system == 'LEED V4 BD+C: NB' || rating_system == 'LEED-NB')
		{
			return 'LEED for Neighborhoods'
		}
		else if(rating_system == 'LEED V4 O+M: EB WP')
		{
			return 'LEED V4 O+M: EB'
		}
		else
		{
			return rating_system
		}
	};

	getParameterFromURLByName(name: any, url: any){
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	};

	getUnitTypeForJson(){
		if (this.appData.get('buildingData').project_type == 'city' || this.appData.get('buildingData').project_type == 'community'){
			if (this.appData.get('buildingData').unitType == 'SI'){
				return 'sqkm';
			}
			else{
				return 'sqmiles';
			}
		}
		else{
			if (this.appData.get('buildingData').unitType == 'SI'){
				return 'sqm';
			}
			else{
				return 'sqft';
			}
		}
	};

	getCountryName(country: any, all_countries: any)
	{
		for(var key in all_countries) 
		{
			if(all_countries[key].code == country)
			{
				return all_countries[key].name;
			}
		}
	};

	getStateName(state: any, all_states: any)
	{
		if (all_states.length == 0){
			return "Not Available";
		}
		for(var key in all_states) {
			
			if(all_states[key].code == state)
			{
				return all_states[key].name;
			}
		}
	};

	getCreditIcon(credit: any){
		var iconPath = "";
		var iconPathArray: any = [];

		if(this.appData.get('buildingData').project_type == 'sites')
		{
			if(credit.category == 'Site Context')
			{
				return "../../assets/images/icons/leed/ss-border.png";
			}
			else if(credit.category == 'Pre-Design Assessment + Planning')
			{
				return "../../assets/images/icons/leed/ss-border.png";
			}
			else if(credit.category == 'Site Design - Water')
			{
				return "../../assets/images/icons/leed/we-border.png";
			}
			else if(credit.category == 'SITE DESIGN - SOIL + VEGETATION')
			{
				return "../../assets/images/icons/leed/ss-border.png";
			}
			else if(credit.category == 'SITE DESIGN - MATERIALS SELECTION')
			{
				return "../../assets/images/icons/leed/mr-border.png";
			}
			else if(credit.category == 'SITE DESIGN - HUMAN HEALTH + WELL-BEING')
			{
				return "../../assets/images/icons/leed/ss-border.png";
			}
			else if(credit.category == 'CONSTRUCTION')
			{
				return "../../assets/images/icons/city/settings.svg";
			}
			else if(credit.category == 'OPERATIONS + MAINTENANCE')
			{
				return "../../assets/images/icons/city/settings.svg";
			}
			else if(credit.category == 'EDUCATION + PERFORMANCE MONITORING')
			{
				return "../../assets/images/icons/leed/id-border.png";
			}
			else if(credit.category == 'INNOVATION OR EXEMPLARY PERFORMANCE')
			{
				return "../../assets/images/icons/leed/id-border.png";
			}
		}

		var creditId = credit.CreditShortId;

		if((this.appData.get('project_type') == 'city' || this.appData.get('project_type') == 'community') && creditId == "base_score")
		{
			return "../../assets/images/icons/city/plus_base.png"; 
		}

		if(credit.CreditcategoryDescrption=="Base Score"){
			// city prereqs
			return "../../assets/images/icons/city/settings.svg"; 
		}  
		
		if(credit.CreditShortId == 'PF901' || credit.CreditShortId == 'PF902' || credit.CreditShortId == 'PF903' || credit.CreditShortId == 'PF904' || credit.CreditShortId == 'PF905' || credit.CreditShortId == 'PF906'){
			// building/city data input                
			
			iconPathArray["PF901"] = "../../assets/images/icons/energy.svg";
			iconPathArray["PF902"] = "../../assets/images/icons/water.svg";
			iconPathArray["PF903"] = "../../assets/images/icons/waste.svg";
			iconPathArray["PF904"] = "../../assets/images/icons/transport.svg";
			iconPathArray["PF905"] = "../../assets/images/icons/human.svg";
			iconPathArray["PF906"] = "../../assets/images/icons/city/settings.svg";

		}else if(!(credit.CreditcategoryDescrption).includes("Performance") 
				&& credit.CreditcategoryDescrption!="Prerequiste"){
			// building prereqs
			var creditCategory = credit.CreditcategoryDescrption;

			iconPathArray["Sustainable Sites"] = "../../assets/images/icons/leed/ss-border.png";
			iconPathArray["Water Efficiency"] = "../../assets/images/icons/leed/we-border.png";
			iconPathArray["Energy and Atmosphere"] = "../../assets/images/icons/leed/ea-border.png";
			iconPathArray["Materials and Resources"] = "../../assets/images/icons/leed/mr-border.png";
			iconPathArray["Indoor Environmental Quality"] = "../../assets/images/icons/leed/iq-border.png";
			iconPathArray["Innovation"] = "../../assets/images/icons/leed/id-border.png";
			
			return iconPathArray[creditCategory];
		
		}else if(credit.CreditcategoryDescrption=="Prerequiste"){
			// city prereqs
			return "../../assets/images/icons/city/settings.svg"; 
		}  
		return iconPathArray[creditId];              
	};

	addToPortfolio(selected_portfolios: any, selected_projects: any, redirect?: any)
	{
		var urls = [], payload = [];

		for(var i = 0; i < selected_portfolios.length; i++)
		{
			urls.push('/portfolios/ID:'+ selected_portfolios[i] +'/assets/');
		}

		for(var i = 0; i < selected_projects.length; i++)
		{
			payload.push({
				"asset_partner_id": selected_projects[i]
			});
		}
		try
		{
			$("#portfolio_filters").parent().removeClass('open');
		}
		catch(e)
		{
			$("#portfolio_filters").hide();
		}
		
		return this.api.async(
			urls,
			payload
		);
	}

	hideTippy()
	{
		$('#scoreTip').slideUp(300);
	}

	showTippy()
	{
		$('#scoreTip').slideDown(300);
	}

	goToExternalLink(redirect_url: string)
	{
		var leed_id = null;
		if (window.location.href.indexOf('home/overview') > -1){
			leed_id = null;
		}
		else{
			leed_id = this.appData.get('leed_id');
		}
		var data = {
			"leed_id": leed_id,
			"referrer_url": window.location.href,
			"redirect_url": redirect_url
		};
		this.api.post('/track-external-redirect/', data).subscribe(
			//success handler
			function(response){
				
			},
			//error handler
			function(response){
				
			}   
		);
	};

	getCookieValue(name: string)
	{
		var b = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
		return b ? b.pop() : '';
	};

	getCSS (attr: string, val: any, unit: any)
	{
		var styles: any = {};
		styles[attr] = val + unit;
		return styles;
	};

	copy(obj: any)
	{
		return JSON.parse(JSON.stringify(obj));
	}

	initTippy(class_name?: string)
	{
		if(class_name == undefined)
		{
			class_name = '.tippy_init';
		}
		tippy(class_name, {size: 'large', animation: 'shift-away', theme: 'light'});
	}

	showMaintenanceBar()
	{
		if(Config.maintenance_banner)
		{
			return
		}
		try
		{
			if(Config.maintenance_apps.indexOf(this.appData.get('app_name')) != -1)
			{
				this.showMaintenanceModal();
				this.goToApp('projects');
			}
			Config.maintenance_banner = true;
			var x: any = {}, nav_bar = $('.navbar').css('height');
			if(nav_bar == undefined)
			{
				nav_bar = '0px';
			}
			$('.maintenance-banner').slideDown();
			$('.navbar').css('cssText', 'top: 40px !important');
			$('.navbar_info').css('top', (parseInt(nav_bar.replace('px', '')) + 40) + 'px');
			x = $('.navbar_info')[0].getBoundingClientRect();
			$('#sidebar').css('cssText', 'top: ' + (parseInt(x.y + x.height)) + 'px !important');
			$('#content').css('cssText', 'top: ' + (parseInt(x.y + x.height)) + 'px !important');
		}
		catch(e)
		{
		}
	}

	hideMaintenanceBar()
	{
		if(!Config.maintenance_banner)
		{
			return;
		}
		try
		{
			Config.maintenance_banner = false;
			var x: any = {}, nav_bar = $('.navbar').css('height');
			if(nav_bar == undefined)
			{
				nav_bar = '0px';
			}
			$('.maintenance-banner').slideUp();
			if(!this.appData.get('LO_referer'))
			{
				$('.navbar').css('cssText', 'top: 0px !important');
			}
			$('.navbar_info').css('top', (parseInt(nav_bar.replace('px', ''))) + 'px');
			$('#sidebar').css('cssText', 'top: ' + (parseInt(x.y + x.height)) + 'px !important');
			$('#content').css('cssText', 'top: ' + (parseInt(x.y + x.height)) + 'px !important');
		}
		catch(e)
		{}
	}

	dataPolling(): any
	{
		if(this.appData.get('app_type') == 'admin' || this.appData.get('app_name') == 'survey' || this.appData.get('app_name') == 're-entry-survey' || this.appData.get('app_name') == 'plaque')
		{
			return;
		}
		this.api.get('/partial-maintenance/').subscribe(
			data =>
			{
				if(data.partial_maintenance)
				{
					this.showMaintenanceBar();
				}
				else
				{
					this.hideMaintenanceBar();
				}
			},
			function(error)
			{}
		);
	};

	sortTable(sort_col: any, key: string, data: any)
	{
		if(Config.sort_col != sort_col)
		{
			Config.sort_order = 'desc';
			Config.sort_col = sort_col;
		}
		else
		{
			Config.sort_order = Config.sort_order == 'desc' ? 'asc' : 'desc';
		}
		data = data.sort(function(a: any,b: any) 
		{ 
			return a[key].localeCompare(b[key]);
		});

		if(Config.sort_order == 'desc')
		{
			data = data.reverse();
		}
	}

	refreshCycle()
	{
		setTimeout(() =>
		{
			this._ngZone.run(() => {  });
		});
	}

	generateString(length: number)
	{
		let result = '', characters: string ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';;
		const charactersLength = characters.length;
		for ( let i = 0; i < length; i++ )
		{
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	getProjectInfo()
	{
		let promises = [
			this.api.get('/assets/LEED:' + this.appData.get('leed_id') + "/"),
			this.api.get('/auth/usgbc/userprofile/')
		];

		forkJoin(promises).subscribe(
			responses =>
			{
				this.appData.set('buildingData', responses[0]);
			}
		);
	}

	changePassword()
	{
		let url = Config.accounts_page + "change-password?redirect_uri=" + window.location.href;
		(<any>window).open(url, '_self').focus();
	}
}
